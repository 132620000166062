export const env = __DEV__ ? "dev" : "prod";

const devConfig = Object.freeze({
  COURSE_SVC_BASE_URL: "https://api-dev.cerah.co", //
  USER_SVC_BASE_URL: "https://api-dev.cerah.co/users2",
  NOTIFICATION_SVC_BASE_URL: "https://api-dev.cerah.co/notifications",
  TEACHER_SVC_BASE_URL: "https://api-dev.cerah.co",
  VOCAB_SVC_BASE_URL: "https://api-dev.cerah.co",
  CLASS_REQUEST_SVC_BASE_URL: "https://api-dev.cerah.co",
  PRICING_SVC_BASE_URL: "https://api-dev.cerah.co",
  EXCHANGE_RATE_SVC_BASE_URL: "https://api-dev.cerah.co",
  ROLLBAR_ACCESS_TOKEN: "0a51e066908f4cdfb0170ed1721ced23",
  TEACHERS_FORUM_ROOM_ID: "fLrmDx5Rn4yELaDmK",
});

const prodConfig = Object.freeze({
  COURSE_SVC_BASE_URL: "https://api.cerah.co",
  USER_SVC_BASE_URL: "https://api.cerah.co/users2",
  NOTIFICATION_SVC_BASE_URL: "https://api.cerah.co/notifications",
  TEACHER_SVC_BASE_URL: "https://api.cerah.co",
  VOCAB_SVC_BASE_URL: "https://api.cerah.co",
  CLASS_REQUEST_SVC_BASE_URL: "https://api.cerah.co",
  PRICING_SVC_BASE_URL: "https://api.cerah.co",
  EXCHANGE_RATE_SVC_BASE_URL: "https://api.cerah.co",
  ROLLBAR_ACCESS_TOKEN: "0a51e066908f4cdfb0170ed1721ced23",
  TEACHERS_FORUM_ROOM_ID: "YTETDR69NrMMmeAFF",
});

export const config = (env === "prod") ? prodConfig : devConfig;
