import React from 'react';
import {Meeting} from "./meeting";
import history from "../history/history";
import {StyleSheet, Button, View, ScrollView, Text, TextInput} from 'react-native';
import {meetingApi} from "../api/meetingApi";
import {palette} from "../style/palette";
import {DatePicker} from "../controls/datePicker";
import moment from 'moment-timezone';
import {MeetingPanel} from "./meetingPanel";

const style = StyleSheet.create({
  rescheduler: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  duration: {
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
    minWidth: 250
  },
  durationInput: {
    fontSize: 20
  },
  datePicker: {
    minWidth: 250,
  },
  label: {
    marginTop: 10,
    fontSize: 18,
    color: palette.$accent1Shade2
  }
});

const datePickerStyle = {
  dateInput: {
    borderColor: palette.$accent1Tint1,
    borderBottomWidth: 1,
  },
  dateText: {
    fontSize: 20
  }
};

export class MeetingRescheduler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submitClicked = this.submitClicked.bind(this);
    if (props.route.params && props.route.params.meeting) {
      this.state.meeting = props.route.params.meeting
    } else {
      history().push("/meetingList");
    }
  }

  render() {
    const m = this.state.meeting;
    const isSubmitting = this.state.isSubmitting;
    return (
      <View style={{flex: 1}}>
        <MeetingPanel meeting={m}/>
        <View style={style.rescheduler}>
          <View>
            <Text style={style.label}>Date & time</Text>
            <DatePicker
              style={style.datePicker}
              date={moment.utc(m.startTime)}
              mode="datetime"
              format="DD-MM-YYYY HH:mm"
              confirmBtnText="Confirm"
              cancelBtnText="Cancel"
              customStyles={datePickerStyle}
              onDateChange={date => { this.setState({meeting: {...m, startTime: format(date)}})}}
            />
            <Text style={style.label}>Duration (minutes)</Text>
            <View style={style.duration}>
              <IntegerInput
                style = {style.durationInput}
                initialValue={m.durationMins}
                onChange={durationMins => this.setState({meeting: {...m, durationMins}})}/>
            </View>
          </View>
        </View>
        <Button onPress={isSubmitting ? () => {} : this.submitClicked}
                title="Submit"
                color={isSubmitting ? palette.$accent1Tint1 : palette.$accent1Shade2}/>
      </View>
    );

    function format(dStr) {
      return moment(dStr, "DD-MM-YYYY HH:mm").toISOString();
    }
  }

  async submitClicked() {
    const st = this.state;
    this.setState({
      isSubmitting: true
    });
    try {
      await meetingApi.updateMeeting(st.meeting);
      this.setState({isSubmitting: false});
      history().push('/meetingList');
      return;
    } catch (e) {
      console.error(e);
    }
    this.setState({isSubmitting: false});
  }
}

class IntegerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
    this.inputChanged = this.inputChanged.bind(this);
  }

  inputChanged(text) {
    let value = parseInt(text.replace(/[^0-9]/g, ''));
    this.setState({value});
    this.props.onChange(value);
  }

  render() {
    const val = this.state.value;
    return (
      <TextInput style={this.props.style}
                 textAlign="center"
                 keyboardType='numeric'
                 value={val > 0 ? val.toString() : ""}
                 onChangeText={this.inputChanged}
                 controlled={true}/>
    );
  }
}