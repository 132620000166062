import React, {useState} from "react";
import {userApi} from "../api/userApi";
import {View, Text, TextInput, TouchableOpacity, StyleSheet} from 'react-native';
import {palette} from "../style/palette";
import history from "../history/history";
import {userSession} from "../api/userSession";
import {Spinner} from "../controls/spinner";
import {Button} from '../controls/button';
import {log} from '../logger/logger';

export const style = StyleSheet.create({
  login: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    alignItems: "center",
  },
  loginForm: {
    alignItems: "stretch",
    maxWidth: 400,
    width: "100%",
  },
  loginBtn: {
    marginTop: 20
  },
  input: {
    borderBottomWidth: 1,
    fontSize: 20,
    height: 40
  },
  error: {
    textAlign: "center",
    fontSize: 20,
    color: "red"
  },
  title: {
    textAlign: "center"
  }
});

export function EmailLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
    <View style={style.login}>
      <View style={style.loginForm}>
        {error &&
          <View><Text style={style.error}>{error}</Text></View>
        }
        <View><Text style={style.title}>Log into you teacher account</Text></View>
        <TextInput style={style.input}
                   onChangeText={setEmail}
                   keyboardType="email-address"
                   autoCapitalize="none"
                   placeholder="e-mail"/>
        <TextInput style={style.input}
                   onChangeText={setPassword}
                   secureTextEntry={true}
                   placeholder="password"/>
        <View style={style.loginBtn}>
          {submitting ?
            <View style={{height: 35}}><Spinner/></View> :
            <Button onPress={submitClicked} title="Log in"/>
          }
        </View>
      </View>
    </View>
    </>
  );

  async function submitClicked() {
    setSubmitting(true);
    try {
      await userApi.login({email: email.trim(), password});
    } catch (e) {
      let msg;
      log.error(`Error while logging in: ${e.status} ${(e.json && (await e.json()).message || e)}`);
      if (e.status === 400) {
        msg = "Revise the format of your email";
      } else if (e.status === 422) {
        msg = "Wrong credentials";
      } else {
        msg = `Could not log in, status: ${e.status}`;
      }
      setError(msg);
      setSubmitting(false);
      return;
    }
    if (userSession.isMustChangePwd()) {
      history().replace('/', {screen: '/account', params: {screen: '/passwordChange'}});
      return;
    }
    history().replace('/');
  }
}

