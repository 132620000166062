import {api} from "./api";

export const meetingApi = {
  getPendingMeetings: async () => {
    return api.courseSvcRequest('GET', `/teacher/pendingMeetings`);
  },
  getScheduledMeetings: async (from, to) => {
    return api.courseSvcRequest('GET', `/teacher/scheduledMeetings?from=${from}&to=${to}`);
  },
  reschedulePlannedMeetings: async (courseId, rescheduleFromMoment) => {
    return api.courseSvcRequest('POST', `/teacher/meetingBatchReschedulings`, {courseId, rescheduleFrom: rescheduleFromMoment.toISOString()});
  },
  reportMeeting: async (classReport) => {
    return api.courseSvcRequest('POST', `/reports`, classReport);
  },
  updateMeeting: async (meeting) => {
    return api.courseSvcRequest('PUT', `/meetings/${meeting._id}`, meeting);
  }
};