import React from 'react';
import {View, Text, FlatList, StyleSheet, TouchableOpacity} from 'react-native';
import {classRequestApi} from "../api/classRequestApi";
import {Spinner} from "../controls/spinner";
import {palette} from "../style/palette";
import history from '../history/history';
import {getFormattedScheduleParts} from './courseProposalDetails';

export const acceptanceStates = {
  NEW: "NEW",
  INTERESTED: "INTERESTED",
  NOT_INTERESTED: "NOT_INTERESTED",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

const openAcceptanceStates = [
  acceptanceStates.NEW,
  acceptanceStates.INTERESTED,
  acceptanceStates.ACCEPTED
];

export const proposalFinalStates = {
  PAID: "PAID",
  WITHDRAWN: "WITHDRAWN"
};

const style = StyleSheet.create({
  noProposals: {
    padding: 20,
    fontSize: 18
  },
  header: {
    width: "100%",
    backgroundColor: palette.$accent1Tint2,
    padding: 5,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: 14
  }
});

const icon = {
  size: 32,
  color: palette.$accent1Shade2
};

const newHeader = {
  isHeader: true,
  label: "new"
};

const acceptedHeader = {
  isHeader: true,
  label: "accepted by student"
};

const interestedHeader = {
  isHeader: true,
  label: "accepted by you"
};

export class CourseProposals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadState();
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidMount() {
    this.removeFocusListener = this.props.navigation.addListener('focus', this.onRefresh);
  }

  componentWillUnmount() {
    this.removeFocusListener();
  }

  async loadState() {
    let openProposals = [];
    try {
        openProposals = (await classRequestApi.getCourseProposals()).filter(cp =>
          openAcceptanceStates.includes(cp.acceptanceState)
        );
    } catch (e) {
      console.error(e);
    }
    //TODO: move this to the back end (that's what indexes are for... what does getCourseProposals() return, by the way, all of them forever?)
    const newProposals = openProposals.filter(cp => cp.acceptanceState === acceptanceStates.NEW);
    const acceptedProposals = openProposals.filter(cp => cp.acceptanceState === acceptanceStates.ACCEPTED);
    const interestedProposals = openProposals.filter(cp => cp.acceptanceState === acceptanceStates.INTERESTED);
    this.setState({
      newProposals: newProposals,
      acceptedProposals: acceptedProposals,
      interestedProposals: interestedProposals,
      listItems: [
        ...(newProposals.length > 0 ? [newHeader] : []), ...newProposals,
        ...(acceptedProposals.length > 0 ? [acceptedHeader] : []), ...acceptedProposals,
        ...(interestedProposals.length > 0 ? [interestedHeader] : []), ...interestedProposals
      ],
      refreshing: false
    });
  }

  render() {
    const st = this.state;
    const items = st.listItems;
    return (
      <View style={{flex: 1}}>
        {items ? (
          items.length > 0 ?
            <FlatList
              data={items}
              keyExtractor={cp => cp._id || cp.label}
              renderItem={renderCourseProposalItem}
              refreshing={!!this.state.refreshing}
              onRefresh={this.onRefresh}
              stickyHeaderIndices={[0, st.newProposals.length + 1, st.newProposals.length + st.acceptedProposals.length + 2]}
            /> :
            <Text style={style.noProposals}>No course proposals pending</Text>
          ) : <Spinner/>
        }
      </View>
    );
  }

  onRefresh() {
    this.setState({refreshing: true});
    this.loadState();
  }
}

const styleCourseEntry = StyleSheet.create({
  item: {
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
    padding: 8,
    minHeight: 62,
  },
  name: {
    fontSize: 16,
    fontWeight: "800",
    color: palette.$accent1Shade2,
    paddingBottom: 2,
  },
  level: {
    fontSize: 14,
    color: palette.$accent1Shade1,
    paddingBottom: 2,
  },
  schedule: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  daySched: {
    flexDirection: "row",
    alignItems: "center",
  },
  time: {
    fontSize: 10,
  },
  day: {
    fontSize: 10,
    fontWeight: "700",
    color: palette.$accent1Shade2,
    textAlign: "right",
    paddingRight: 3,
    width: 35,
  }
});

function renderCourseProposalItem({item: cp}) {
  if (cp.isHeader) {
    return <Header header={cp}/>
  }
  return (
    <TouchableOpacity style={styleCourseEntry.item} onPress={() => showCourseProposalDetails(cp)}>
      <Text style={styleCourseEntry.name}>{cp.name} ({cp.level || "A2"})</Text>
      <Schedule schedule={cp.schedule} tz={cp.timezone}/>
    </TouchableOpacity>
  );
}

function Header(props) {
  return (
    <Text style={style.header}>{props.header.label}</Text>
  )
}

function Schedule(props) {
  return (
    <View style={styleCourseEntry.schedule}>
      {props.schedule.map((d, idx) => <DaySched key={idx} sched={d} tz={props.tz}/>)}
    </View>
  );

  function DaySched(props) {
    let dayOfWeekStr = "";
    let startStr = "-";
    let endStr = "-";
    if (props.sched) {
      ({dayOfWeekStr, startStr, endStr} = getFormattedScheduleParts(props.sched, props.tz));
    }
    return (
      <View style={styleCourseEntry.daySched}>
        <Text style={styleCourseEntry.day}>{dayOfWeekStr}</Text>
        <Text style={styleCourseEntry.time}>{startStr} - {endStr}</Text>
      </View>
    )
  }
}

function showCourseProposalDetails(cp) {
  history().push(`/courseProposals/details`, {courseProposal: cp});
}