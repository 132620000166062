import React from "react";
import {userApi} from "../api/userApi";
import {View, Text, TextInput, StyleSheet, TouchableOpacity} from 'react-native';
import {palette} from "../style/palette";
import history from "../history/history";
import {userSession} from "../api/userSession";
import {Spinner} from "../controls/spinner";
import {Button} from '../controls/button';

export const style = StyleSheet.create({
  login: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
    padding: 20,
    textAlign: "center"
  },
  loginBtn: {
    marginTop: 20
  },
  input: {
    borderBottomWidth: 1,
    fontSize: 20,
    height: 40
  },
  error: {
    textAlign: "center",
    fontSize: 20,
    color: "red"
  }
});

export class PasswordChange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curPassword: "",
      newPassword: "",
      confirmedPwd: ""
    };
    this.submitClicked = this.submitClicked.bind(this);
    this.currentPwdChanged = this.currentPwdChanged.bind(this);
    this.newPwdChanged = this.newPwdChanged.bind(this);
    this.confirmPwdChanged = this.confirmPwdChanged.bind(this);
  }

  render() {
    //TODO: validation in the form
    return (
      <>
      <View style={style.login}>
        {this.state.error &&
          <View><Text style={style.error}>{this.state.error}</Text></View>
        }
        <View><Text>Change your password</Text></View>
        <TextInput style={style.input}
                   onChangeText={this.currentPwdChanged}
                   secureTextEntry={true}
                   placeholder="your current password"/>
        <TextInput style={style.input}
                   onChangeText={this.newPwdChanged}
                   secureTextEntry={true}
                   placeholder="your new password"/>
        <TextInput style={style.input}
                   onChangeText={this.confirmPwdChanged}
                   secureTextEntry={true}
                   placeholder="confirm new password"/>
        <View style={style.loginBtn}>
          {this.state.submitting ?
            <View style={{height: 35}}><Spinner/></View> :
            <Button onPress={this.submitClicked} title="Submit"/>
          }
        </View>
      </View>
      <TouchableOpacity style={{alignSelf: "flex-end", margin: 20}} onPress={logout}>
        <Text style={{color: palette.$accent1Shade1}}>Log out</Text>
      </TouchableOpacity>
      </>
    );
  }

  currentPwdChanged(curPassword) {
    this.setState({curPassword});
  }

  newPwdChanged(newPassword) {
    this.setState({newPassword});
  }

  confirmPwdChanged(confirmedPwd) {
    this.setState({confirmedPwd});
  }

  async submitClicked() {
    const st = this.state;
    this.setState({submitting: true});
    if (st.newPassword !== st.confirmedPwd) {
      this.setState({error: "Passwords don't match"});
      this.setState({submitting: false});
      return;
    }
    try {
      await userApi.changePwd({
        userId: await userSession.getUserId(),
        curPassword: this.state.curPassword,
        newPassword: this.state.newPassword
      });
      history().replace('/login');
    } catch (e) {
      if (e.status === 422)
        this.setState({error: "Wrong current password"});
      else
        this.setState({error: "There was an error, try again"});
      console.log("error while resetting password");
    }
    this.setState({submitting: false});
  }
}

function logout() {
  userSession.clearSession();
  history().replace("/login");
}