import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {pushNotifications} from "../expo/push/pushNotifications";
import {userApi} from "./userApi";
import {log} from '../logger/logger';
import {chatSession} from "cerah-chat";
import {chatApi} from './chatApi';

const accessTokenKey = 'cerah.accessToken';
const refreshTokenKey = 'cerah.rToken';
const userKey = 'cerah.userId';
const nameKey = 'cerah.userName';
const accessTokenExpiryKey = 'cerah.jwtExpiry';

const storage = AsyncStorage;

export const userSession = {
  userChanged: async (token) => {
    if (token.mustChangePwd) {
      storage.removeItem(accessTokenKey);
      storage.removeItem(accessTokenExpiryKey);
      storage.setItem(userKey, token.userId);
      userSession.mustChangePwd = true;
      return;
    }
    const payload = jwtDecode(token.accessToken);
    log.updateUser(payload.userId);
    storage.setItem(userKey, payload.userId);
    storage.setItem(accessTokenExpiryKey, payload.exp.toString());
    storage.setItem(accessTokenKey, token.accessToken);
    storage.setItem(refreshTokenKey, token.refreshToken);
    userSession.mustChangePwd = false;
    try {
      await pushNotifications.init();
    } catch (e) {
      log.error("Could not initialize push notifications", e);
    }
    try {
      await chatSession.login(chatApi.getTeachersForumRoomId(), await userSession.getToken(), (...args) => log.error(...args));
    } catch (e) {
      log.error(`Error logging in the teacher-${await userSession.getUserId()} to rocket chat with roomId ${chatApi.getTeachersForumRoomId()}`);
    }
    try {
      const user = await userApi.getUser();
      await userSession.updateName(user.name);
    } catch (e) {
      log.error(JSON.stringify(e));
    }
  },

  clearSession: () => {
    storage.removeItem(userKey);
    storage.removeItem(accessTokenKey);
    storage.removeItem(accessTokenExpiryKey);
    storage.removeItem(refreshTokenKey);
  },

  getUserId: async () => {
    return storage.getItem(userKey);
  },

  getToken: async () => {
    if (await userSession.getAccessTokenExpiry() < Date.now() / 1000) {
      try {
        await refreshAccessToken();
      } catch (e) {
        userSession.clearSession();
      }
    }
    return storage.getItem(accessTokenKey);
  },

  getAccessTokenExpiry: async ()=> {
    return parseInt(await storage.getItem(accessTokenExpiryKey));
  },

  getRefreshToken: async () => {
    return storage.getItem(refreshTokenKey);
  },

  isMustChangePwd: () => {
    return userSession.mustChangePwd;
  },

  getName: async () => {
    return storage.getItem(nameKey);
  },

  updateName: async (name) => {
    return storage.setItem(nameKey, name);
  }
};

async function refreshAccessToken() {
  const newToken = await userApi.refreshToken();
  userSession.userChanged(newToken);
}
