import React from 'react';
import moment from "moment-timezone";
import {Text, View, StyleSheet} from "react-native";
import {palette} from "../style/palette";

const days = [1, 2, 3, 4, 5, 6, 7];
const array24 = new Array(24).fill(0);

export function EventsSchedule({startDate, events}) {
  const eventsByDay = [...new Array(7)].map(() => []);
  events.forEach(ev => {
    const mmnt = moment(ev.startTime);
    const dayOfWeek = Math.floor((mmnt.valueOf() - startDate.valueOf())/86400000);
    if (dayOfWeek >=0 && dayOfWeek < 7) {
      eventsByDay[dayOfWeek].push({
        startDayPercent: (1 + mmnt.hours() + (mmnt.minutes() / 60)) / 0.25,
        durationDayPercent: ev.durationMins / 14.40 * 24 / 25,
        name: ev.name,
      });
    }
  });
  return (
    <View style={style.frame}>
      <View style={style.axis}>
        <View style={style.tick}/>
        {array24.map((h, idx) =>
          <Text key={"tick" + idx} style={style.tick}>{moment().hours(idx).minutes(0).format("HH:mm")}</Text>
        )}
      </View>
      <View style={style.week}>
        {days.map((d, idx) =>
          <View key={d} style={style.day}>
            <Text key={"head"} style={style.dayLabel} adjustsFontSizeToFit>{moment(startDate).add(idx, 'days').format("dd D/M")}</Text>
            {eventsByDay[idx].map((ev, idx2) =>
              <View key={`ev${idx2}`}
                    style={{
                      ...meetingStyle,
                      top: `${ev.startDayPercent}%`,
                      height: `${ev.durationDayPercent}%`,
                      justifyContent: "center"
                    }}
              >
                <Text adjustsFontSizeToFit>{ev.name.substring(0, 15) + (ev.name.length > 15 ? "..." : "")}</Text>
              </View>
            )}
            {array24.map((h, idx) => <View key={idx} style={style.hour}/>)}
          </View>
        )}
      </View>
    </View>
  )
}

const meetingStyle = {
  position: "absolute",
  borderColor: palette.$accent1Shade2,
  borderWidth: 1,
  borderRadius: 5,
  width: "100%",
  fontSize: 10,
  textAlign: "center",
  backgroundColor: "#455d8920",
  zIndex: 10,
};

const style = StyleSheet.create({
  tick: {
    fontSize: 10,
    height: "4%",
  },
  frame: {
    flexDirection: "row",
    flex: 1,
    width: "100%",
  },
  axis: {
    flexDirection: "column",
    alignItems: "flex-end",
    borderColor: "transparent",
    borderWidth: 1,
    position: "relative",
    top: -5,
  },
  week: {
    flexDirection: "row",
    // justifyContent: "stretch",
    height: "100%",
    width: "100%",
    flexShrink: 1,
  },
  day: {
    borderColor: "black",
    borderWidth: .5,
    position: "relative",
    flex: 1,
  },
  dayLabel: {
    fontSize: 10,
    height: "4%",
    backgroundColor: palette.$accent1Tint2,
    textAlign: "center",
    flexShrink: 0,
  },
  hour: {
    height: "4%",
    width: "100%",
    borderColor: "#bedbff",
    borderBottomWidth: 1,
  },
})