import {config} from '../config';
import {userSession} from "./userSession";
import history from '../history/history';

export const refreshApi = {
  get: async(path) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET"
    };

    const token = await userSession.getRefreshToken();
    if (token) {
      options.headers["x-cerah-auth"] = `Bearer ${token}`
    }

    return fetch(new Request(`${config.USER_SVC_BASE_URL}${path}`, options))
    .then((response) => {
      if (!response.ok) {
        if (response.status === 401 || response.status === 403) {
          history().push('/login');
          return null;
        }
        return Promise.reject(response);
      }
      if (response.status === 204) {
        return null;
      }
      return response.json();
    });
  }
};