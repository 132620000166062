import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { palette } from "../style/palette";

const style = StyleSheet.create({
  item: {
    justifyContent:"center",
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
    padding: 8,
    minHeight: 62,
  },
  name: {
    fontSize: 16,
    fontWeight: "800",
    color: palette.$accent1Shade2,
    paddingBottom: 2,
  },
});

export function Account({navigation}) {
  return (
    <View>
      <TouchableOpacity style={style.item} onPress={() => navigation.navigate("/payslips")}>
        <Text style={style.name}>Payslips</Text>
      </TouchableOpacity>
      <TouchableOpacity style={style.item} onPress={() => navigation.navigate("/passwordChange")}>
        <Text style={style.name}>Password change</Text>
      </TouchableOpacity>
    </View>
  )
}