import * as Notifications from 'expo-notifications'
import * as Permissions from 'expo-permissions'
import {userApi} from "../../api/userApi";
import {classRequestApi} from "../../api/classRequestApi";
import history from "../../history/history";
import {Platform} from "react-native";
import {log} from "../../logger/logger";

let requestingPermission = false;

export const pushNotifications = {
  init: async() => {
    if (Platform.OS === "web" || requestingPermission) return;
    
    requestingPermission = true;
    let {status} = await Permissions.getAsync(Permissions.NOTIFICATIONS);

    if (status !== 'granted')
      status = (await Permissions.askAsync(Permissions.NOTIFICATIONS)).status;
  
    requestingPermission = false;

    if (status !== 'granted')
      return;

    Notifications.setNotificationHandler({
      handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: true,
      }),
    });

    const notificationSubscription = Notifications.addNotificationResponseReceivedListener(pushNotifications.handleResponse);
  
    const token = await Notifications.getExpoPushTokenAsync();

    try {
      await userApi.updateNotificationsToken({
        token: token?.data,
        // deviceToken: deviceToken?.data
      });
    } catch (e) {
      log.error("Could not update notification token", e);
    }
  
    return () => {
      Notifications.removeNotificationSubscription(notificationSubscription);
    }
  },

  handleResponse: async (action) => {
    const n = action?.notification?.request?.content;
    if (!n) return;
    if (n.origin !== "received" && n.data && n.data.type) {
      switch (n.data.type) {
        case "newCourseProposal":
        case "courseProposalRejection":
        case "courseProposalPick":
        case "courseProposalWithdrawal":
          const cp = await classRequestApi.getCourseProposal(n.data.courseProposalId);
          await history().push(`/courseProposals`);
          history().push(`/courseProposals`, {screen: `/courseProposals/details`, params: {courseProposal: cp}});
          break;
        case "newPayslip":
          history().push("/", {screen: "/account", params: {screen: "/payslipViewer", params: {id: n.data.payslipId}}});
          break;
      }
    }
  }
};