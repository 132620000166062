import React, {useEffect, useState} from 'react';
import {StyleSheet, FlatList, View, Text, ScrollView, RefreshControl, TouchableOpacity, Dimensions} from 'react-native';
import {meetingApi} from "../api/meetingApi";
import {palette} from "../style/palette";
import {Spinner} from "../controls/spinner";
import moment from "moment";
import {MeetingItem} from "./meetingItem";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {TeacherSchedule} from "../calendar/teacherSchedule";
import {log} from "../logger/logger";
import {userSession} from "../api/userSession";

const style = StyleSheet.create({
  feed: {
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start"
  },
  sectionHeader: {
    padding: 5,
    backgroundColor: palette.$accent1
  },
  dueSectionStyle: {
    flexShrink: 0,
    flexGrow: 0
  },
  sectionTitle: {
    color: "white"
  },
  noMeetings: {
    padding: 20,
    fontSize: 18
  },
  switchTabButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 30,
    paddingHorizontal: 16,
  },
  switchTabButtonText: {
    color: palette.$greyText,
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontSize: 14,
    fontWeight: "bold"
  }
});

export const SwitchTabButton = ({onPress, title, icon, isSelected}) => (
  <TouchableOpacity onPress={onPress}
                    style={[style.switchTabButtonContainer, isSelected && {backgroundColor: palette.$accent1Shade2}]}>
    <MaterialCommunityIcons name={icon} size={26} color={isSelected ? palette.$grey : palette.$greyText}/>
    <Text style={[style.switchTabButtonText, isSelected && {color: palette.$grey}]}>{title}</Text>
  </TouchableOpacity>
);

export function MeetingsFeed(props) {
  const [refreshing, setRefreshing] = useState(false);
  const [viewStyleIndex, setViewStyleIndex] = useState(0);
  const [due, setDue] = useState();
  const [oncoming, setOncoming] = useState();

  useEffect(() => {
    refreshList();
    return props.navigation.addListener('focus', refreshList);
  }, [])

  async function refreshList() {
    setRefreshing(true);
    try {
      const allMeetings = await meetingApi.getPendingMeetings();
      const due = allMeetings?.filter(m =>
        isPastMeeting(m) && m.state === 'PLANNED'
      );
      const oncoming = allMeetings?.filter(m => !isPastMeeting(m));
      setDue(due);
      setOncoming(oncoming)
    } catch (e) {
      log.error(`Failed to retrieve meetings for user ${await userSession.getUserId()}`, e);
    } finally {
      setRefreshing(false);
    }
  }

  return (
    <View style={style.feed}>
      <View style={{flexDirection: "row", justifyContent: "space-evenly", paddingVertical: 10}}>
        <SwitchTabButton title={"All meetings"} onPress={() => setViewStyleIndex(0)} icon={"view-list-outline"}
                         isSelected={viewStyleIndex === 0}/>
        <SwitchTabButton title={"Calendar view"} onPress={() => setViewStyleIndex(1)} icon={"calendar"}
                         isSelected={viewStyleIndex === 1}/>
      </View>

      {viewStyleIndex === 0 && <View style={{flex: 1}}>

        <View style={style.sectionHeader}>
          <Text style={style.sectionTitle}>Action required</Text>
        </View>
        {due ? (
          due.length > 0 ?
            <FlatList
              data={due}
              keyExtractor={m => m._id}
              renderItem={renderMeetingItem}
              style={style.dueSectionStyle}
              refreshing={refreshing}
              onRefresh={refreshList}
            /> :
            <NoMeetings msg="No meetings requiring action"/>
        ) : <Spinner/>}

        <View style={style.sectionHeader}>
          <Text style={style.sectionTitle}>Oncoming meetings</Text>
        </View>
          {oncoming ? (
            oncoming.length > 0 ?
              <FlatList
                data={oncoming}
                keyExtractor={m => m._id}
                renderItem={renderMeetingItem}
                refreshing={refreshing}
                onRefresh={refreshList}
              /> :
              <NoMeetings msg="You have no oncoming meetings"/>
          ) : <Spinner/>}
        </View>

      }

      {viewStyleIndex === 1 && <View style={{flex: 1}}>
        <TeacherSchedule/>
      </View>}

    </View>
  );

  function NoMeetings(props) {
    return (
      <ScrollView
        style={style.dueSectionStyle}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={refreshList}
          />
        }
      >
        <Text style={style.noMeetings}>{props.msg}</Text>
      </ScrollView>
    );
  }
}

function renderMeetingItem({item: m}) {
  return (
    <MeetingItem meeting={m}/>
  );
}

function isPastMeeting(m) {
  return m.startTime && moment(m.startTime).isBefore();
}