import React, {useRef, useState, useEffect} from 'react';
import {View, TouchableOpacity, Text, StyleSheet, TextInput} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import moment from "moment-timezone";
import {palette} from "../style/palette";


const defaultSchedule = {
  durationMins: 60,
  dayOfWeek: 3,
  startHours: 19
};

const buttonSize = 25;

const style = StyleSheet.create({
  noSched: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 20
  },
  schedule: {
    backgroundColor: palette.$accent1Tint2,
    borderRadius: 20,
    marginHorizontal: 20,
    padding: 10,
    marginVertical: 10,
  },
  header: {
    flexDirection: "row",
    paddingRight: buttonSize * 2 + 10
  },
  headerDay: {
    fontSize: 10,
    textAlign: "center",
    flex: 1,
  },
  dayPicker: {
    flexGrow: 1,
  },
  headerTime: {
    fontSize: 10,
    width: 45,
    textAlign: "center",
  },
  hoursField: {
    width: 20,
    fontSize: 16,
    textAlign: "right",
  },
  colon: {
    width: 5,
    textAlign: "center"
  },
  minsField: {
    width: 20,
    fontSize: 16,
    textAlign: "left"
  },
  headerDuration: {
    fontSize: 10,
    textAlign: "center",
    width: 45
  },
  durationField: {
    textAlign: "center",
    fontSize: 16,
    width: 45,
  },
  scheduleLine: {
    flexDirection: "row",
    justifyContent: "center",
  },
  tinyButton: {
    alignSelf: "center",
    padding: 6,
    backgroundColor: palette.$accent1Shade2,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    borderRadius: buttonSize,
    width: buttonSize,
    height: buttonSize
  },
  button: {
    alignSelf: "center",
    paddingHorizontal: 30,
    paddingVertical: 8,
    backgroundColor: palette.$accent1Shade2,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    borderRadius: 100,
  },
  buttonText: {
    color: "white",
    fontWeight: "700",
    fontSize: 16
  },
  tinyButtonDisabled: {
    backgroundColor: palette.$accent1Tint1
  },
  tinyButtonText: {
    color: "white",
    fontWeight: "700",
    fontSize: 14
  },
  class: {
    flexGrow: 1,
    flexDirection: "row",
    alignItems: "center"
  },
});

export function Schedule({schedule, tz, onChange}) {
  const [editing, setEditing] = useState(false);
  const [sched, setSchedule] = useState();
  useEffect(() => {setSchedule(schedule)}, [schedule]);

  return (
    <View style={style.schedule}>
      {sched && sched.length === 0 &&
        <Text style={style.noSched}>Schedule not set (flexible)</Text>
      }
      {sched && sched.length > 0 &&
        <>
        <View style={style.header}>
          <Text style={style.headerDay}>day</Text>
          <Text style={style.headerTime}>time</Text>
          <Text style={style.headerDuration}>duration</Text>
        </View>
        {sched.map((cls, index) => (
          <View key={index} style={style.scheduleLine}>
            <Class cls={cls}
                   tz={tz}
                   disabled={!editing}
                   onChange={cls => {
                     sched.splice(index, 1, cls);
                     setSchedule([...sched]);
                   }}/>
            <TinyButton disabled={!editing}
                        onPress={() => {
                          sched.splice(index, 1);
                          setSchedule([...sched]);
                        }}
                        title="-"/>
            <TinyButton disabled={!editing}
                        onPress={() => {
                          sched.splice(index + 1, 0, Object.assign({}, defaultSchedule));
                          setSchedule([...sched]);
                        }}
                        title="+"/>
          </View>
        ))}
        </>
      }
      {!editing && <Button onPress={edit} title="Edit"/>}
      {editing && <Button onPress={save} title="Save changes"/>}
    </View>
  );

  function edit() {
    if (sched.length === 0) setSchedule([Object.assign({}, defaultSchedule)]);
    setEditing(true);
  }

  function save(){
    onChange(sched);
    setEditing(false);
  }
}


function TinyButton({onPress, title, disabled}) {
  let btnStyle = style.tinyButton;
  if (disabled) {
    btnStyle = [btnStyle, style.tinyButtonDisabled];
  }
  return (
    <TouchableOpacity style={btnStyle} onPress={() => !disabled && onPress()}>
      <Text style={style.tinyButtonText}>{title}</Text>
    </TouchableOpacity>
  )
}


const Class = ({cls, tz, onChange, disabled}) => {
  function getStartTimeLocal() {
    const hours = Math.floor(cls.startHours);
    const minutes = Math.round((cls.startHours - hours) * 60);
    return moment(moment().startOf("isoWeek").tz(tz).isoWeekday(cls.dayOfWeek).hours(hours).minutes(minutes).valueOf());
  }

  function padLeftZeros(number) {
    return number > 9 ? "" + number : number > 0 ? "0" + number : "00";
  }

  function hoursTextChanged(text) {
    const digits = text.replace(/[^0-9]/g, '');
    setTextHours(digits);
    if (digits.length < 2) return;
    hoursChanged(text);
    minutesEl.current.focus();
  }

  function minutesTextChanged(text) {
    const digits = text.replace(/[^0-9]/g, '');
    setTextMinutes(digits);
    if (digits.length < 2) return;
    minutesChanged(text);
    durationEl.current.focus();
  }

  function hoursChanged(text) {
    const digits = text.replace(/[^0-9]/g, '');
    const newLocalHours = (parseInt(digits) || 0) % 24;
    const localTime = getStartTimeLocal();
    const newRemoteTime = localTime.hours(newLocalHours).tz(tz).isoWeekday(localTime.isoWeekday());

    const hours = newRemoteTime.hours();
    const dayOfWeek = newRemoteTime.isoWeekday();
    const minutes = newRemoteTime.minutes();

    const oldStartHours = cls.startHours;
    cls.startHours = hours + minutes / 60;
    cls.dayOfWeek = dayOfWeek;
    setTextHours(padLeftZeros(newLocalHours));
    if (oldStartHours !== cls.startHours)
      onChange(cls);
  }

  function minutesChanged(text) {
    const digits = text.replace(/[^0-9]/g, '');
    const hours = Math.floor(cls.startHours);
    const minutes = (parseInt(digits) || 0) % 60;
    const oldStartHours = cls.startHours;
    cls.startHours = (minutes / 60 + hours);
    setTextMinutes(padLeftZeros(minutes));
    if (oldStartHours !== cls.startHours)
      onChange(cls)
  }

  function weekdayChanged(day) {
    if (typeof day === "string") {
      day = Number(day);
    }
    cls.dayOfWeek = getStartTimeLocal().isoWeekday(day).tz(tz).isoWeekday();
    onChange(cls);
  }

  function durationChanged(text) {
    cls.durationMins = parseInt(text) || 0;
    onChange(cls);
  }

  const minutesEl = useRef();
  const durationEl = useRef();

  const localTime = getStartTimeLocal(cls);
  const hours = localTime.hours();
  const minutes = localTime.minutes();
  const dayOfWeek = localTime.isoWeekday();

  const [textMinutes, setTextMinutes] = useState(padLeftZeros(minutes));
  const [textHours, setTextHours] = useState(padLeftZeros(hours));

  return (
    <View style={style.class}>
      <Picker style={style.dayPicker} selectedValue={dayOfWeek} onValueChange={weekdayChanged} enabled={!disabled}>
        {new Array(7).fill().map((v, idx) =>
          <Picker.Item key={idx+1} value={idx + 1} label={moment().isoWeekday(idx + 1).format('dddd')}/>
        )}
      </Picker>
      <TextInput keyboardType='numeric'
                 value={textHours}
                 maxLength={2}
                 selectTextOnFocus={!disabled}
                 style={style.hoursField}
                 editable={!disabled}
                 onEndEditing={ev => hoursChanged(ev.nativeEvent.text)}
                 onChangeText={hoursTextChanged}/>
      <Text style={style.colon}>:</Text>
      <TextInput keyboardType='numeric'
                 ref={minutesEl}
                 value={textMinutes}
                 maxLength={2}
                 style={style.minsField}
                 selectTextOnFocus={!disabled}
                 onEndEditing={ev => minutesChanged(ev.nativeEvent.text)}
                 editable={!disabled}
                 onChangeText={minutesTextChanged}/>
      <TextInput keyboardType='numeric'
                 ref={durationEl}
                 selectTextOnFocus={!disabled}
                 editable={!disabled}
                 defaultValue={'' + cls.durationMins}
                 style={style.durationField}
                 onChangeText={durationChanged}/>
    </View>
  )
};


function Button({onPress, title, disabled, children}) {
  return (
    <View style={{opacity: disabled ? 0 : 1}}>
      <TouchableOpacity style={style.button} onPress={onPress} disabled={disabled}>
        {title && <Text style={style.buttonText}>{title}</Text>}
        {children}
      </TouchableOpacity>
    </View>
  )
}