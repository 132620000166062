import {api} from "./api";

export const courseApi = {
  getStudents: async (courseId, whenMillis) => {
    return api.courseSvcRequest('GET', `/students?courseId=${courseId}&when=${whenMillis || Date.now()}`);
  },

  getActiveCourses: async () => {
    return api.courseSvcRequest('GET', `/teacher/activeCourses`);
  },

  getPayslips: async () => {
    return api.courseSvcRequest('GET', `/teacher/payslips`);
  },

  getPayslip: async (id) => {
    return api.courseSvcRequest('GET', `/teacher/payslips/${id}`);
  },

  updateCourse: async (course) => {
    return api.courseSvcRequest('PUT', `/teacher/courses/${course._id}`, {schedule: course.schedule});
  },

  getCourse: async (courseId) => {
    return api.courseSvcRequest('GET', `/teacher/courses/${courseId}`);
  },

  acceptPayslip: async (payslip) => {
    return api.courseSvcRequest("POST", `/teacher/payslipAcceptances`, {payslipId: payslip._id});
  }
};
