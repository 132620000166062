export const subjectHumanReadableNames = {
  "english.bareng.A1": "Group: Gen. English - A1",
  "english.bareng.A2": "Group: Gen. English - A2",
  "english.bareng.B1": "Group: Gen. English - B1",
  "english.bareng.B2": "Group: Gen. English - B2",
  "english.bareng.IELTS": "Group: IELTS",
  "english.conversation": "Conversation",
  "english.general": "General English",
  "english.business": "Business English",
  "english.IELTS": "IELTS preparation",
  "english.TOEFL": "TOEFL preparation",
  "english.APTIS": "APTIS preparation",
  "english.essay": "Essay writing for applications",
  "german.general": "German",
  "bahasa.general": "Indonesian",
  "mandarin.general": "Mandarin",
  "event.IELTSsim": "Event - IELTS simulation",
  "event.study-abroad": "Event - Advice to study abroad",
  "event.speaking": "Event - Speaking activity"
};
