import React, {useState} from 'react';
import {TouchableOpacity, Text, Platform} from 'react-native';
import NativeDatePicker from '@react-native-community/datetimepicker';
import {LocalizationProvider, DateTimePicker as WebDatePicker} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import TextField from '@mui/material/TextField';

export class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateDate: moment(props.date).tz(moment.tz.guess(true)),
      isShowing: false
    };
  }

  showNativePicker() {
    this.setState({
      mode: this.props.mode !== "time" ? "date" : "time",
      isShowing: true
    })
  }

  render() {
    return (
      <TouchableOpacity onPress={() => this.showNativePicker()}>
        {(Platform.OS === "web") ?
          this.renderWebDatePicker() :
          this.renderNativePicker()}
      </TouchableOpacity>
    );
  }

  renderNativePicker() {
    const {isShowing, mode, stateDate} = this.state;
    const {format} = this.props;
    const {dateInput, dateText} = this.props.customStyles;
    return (
      <>
      <Text style={{textAlign: "center", ...dateInput, ...dateText}}>{stateDate.format(format)}</Text>
      {isShowing && <NativeDatePicker
        mode={mode}
        value={new Date(stateDate.valueOf())}
        onChange={(event, date) => {
          if (!date) {
            this.setState({isShowing: false});
            return;
          }
          if (mode === "date") {
            if (this.props.mode === "datetime") {
              this.setState({
                mode: "time",
                isShowing: true,
                stateDate: moment(date)
              });
            } else {
              this.setState({
                isShowing: false,
                stateDate: moment(date)
              });
              setTimeout(() =>
                this.props.onDateChange(moment(date).format(format))
              );
            }
          } else {
            this.setState({
              isShowing: false,
              stateDate: moment(date)
            });
            setTimeout(() =>
              this.props.onDateChange(moment(date).format(format))
            );
          }
        }}
      />}
      </>
    );
  }

  renderWebDatePicker() {
    const {format} = this.props;
    const {dateInput, dateText} = this.props.customStyles;
    return (<LocalizationProvider dateAdapter={AdapterMoment}>
        <WebDatePicker
          inputFormat={format}
          value={this.state.stateDate}
          renderInput={props => <TextField {...props} style={{...dateInput, ...dateText}}/>}
          ampm={false}
          onChange={(date) => {
            this.setState({stateDate: date});
            this.props.onDateChange(date)
          }}
        />
    </LocalizationProvider>);
  }
}

