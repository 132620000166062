import React from 'react';
import {Meeting} from "./meeting";
import history from "../history/history";
import {StyleSheet, View, Text} from 'react-native';
import {meetingApi} from "../api/meetingApi";
import {palette} from "../style/palette";
import {Entypo} from "@expo/vector-icons";
import {log} from "../logger/logger";

const style = StyleSheet.create({
  outer: {
    flex: 1
  },
  meetingPanel: {
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1
  },
  rescheduler: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: "row",
    justifyContent: "center"
  },
  option: {
    alignItems: "center",
    width: 100,
    margin: 10
  },
  label: {
    marginTop: 10,
    fontSize: 12,
    textAlign: "center",
    color: palette.$accent1Shade2
  }
});

const icon = {
  size: 60,
  color: palette.$accent1Shade2,
  disabledColor: palette.$accent1Tint1,
};

export class MeetingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false
    };
    this.rescheduleToDate = this.rescheduleToDate.bind(this);
    this.rescheduleToEnd = this.rescheduleToEnd.bind(this);
    if (props.route.params && props.route.params.meeting) {
      this.state.meeting = props.route.params.meeting
    } else {
      history().push("/meetingList");
    }
  }

  render() {
    const m = this.state.meeting;
    const isSubmitting = this.state.isSubmitting;
    return (
      <View style={style.outer}>
        <View style={{flex: 1}}/>
        <View style={style.meetingPanel}>
          <Meeting meeting={m}/>
        </View>
        <View style={style.rescheduler}>
          <View style={style.option}>
            <Entypo name="arrow-down"
                    onPress={isSubmitting ? () => {} : this.rescheduleToEnd}
                    size={icon.size}
                    color={isSubmitting ? icon.disabledColor : icon.color}/>
            <Text style={style.label}>Move to the end</Text>
          </View>
          <View style={style.option}>
            <Entypo name="calendar" onPress={this.rescheduleToDate} size={icon.size} color={icon.color}/>
            <Text style={style.label}>Change date or duration</Text>
          </View>
        </View>
        <View style={{flex: 1}}/>
      </View>
    );
  }

  async rescheduleToEnd() {
    this.setState({isSubmitting: true});
    const m = this.state.meeting;
    try {
      await meetingApi.updateMeeting({
        _id: m._id,
        durationMins: m.durationMins
        //no startTime so that it's rescheduled to the end
      });
      this.setState({isSubmitting: false});
      history().push('/meetingList');
      return;
    } catch (e) {
      log.error(`Couldn't reschedule meeting ${m._id} in course ${m.courseId} to the end`, e);
    }
    this.setState({isSubmitting: false});
  }

  async rescheduleToDate() {
    const m = this.state.meeting;
    history().push(`/meetings/rescheduleToDate`, {meeting: m});
  }
}
