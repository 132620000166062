import React, {useEffect, useState} from 'react';
import {EventsSchedule} from "./eventsSchedule";
import moment from "moment-timezone";
import {Button, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {meetingApi} from "../api/meetingApi";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {palette} from "../style/palette";

export function TeacherSchedule() {
  const [weekOffset, setWeekOffset] = useState(0);
  const [events, setEvents] = useState([]);
  const startOfWeek = moment().startOf("isoWeek").add(weekOffset, 'week'); //This should be config dependent, instead of always Monday
  const lastMonday = moment(startOfWeek.valueOf());
  const nextMonday = moment(lastMonday).add(1, 'week');

  async function loadMeetings() {
    const meetings = await meetingApi.getScheduledMeetings(moment(lastMonday).subtract(1, 'millisecond'), nextMonday);
    const events = meetings.map(m => ({
      startTime: m.startTime,
      durationMins: m.durationMins,
      name: m.course.name
    }));
    setEvents(events);
  }

  useEffect(() => {
    loadMeetings()
  }, [weekOffset]);

  return (
    <>
      <View style={style.buttonContainer}>
        <TouchableOpacity onPress={() => setWeekOffset(weekOffset - 1)}>
          <MaterialCommunityIcons name="chevron-left" size={28} color={palette.$accent1Shade2}/>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => setWeekOffset(weekOffset + 1)}>
          <MaterialCommunityIcons name="chevron-right" size={28} color={palette.$accent1Shade2}/>
        </TouchableOpacity>
      </View>
      <EventsSchedule events={events} startDate={lastMonday}/>
    </>
  );
}

const style = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 5,
  },
})

