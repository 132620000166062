import React, {useState, useEffect} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, ScrollView} from 'react-native';
import {Schedule} from "./courseSchedule";
import {courseApi} from "../api/courseApi";
import {palette} from "../style/palette";
import {MeetingItem} from "../meetings/meetingItem";
import {meetingApi} from "../api/meetingApi";
import {Entypo} from "@expo/vector-icons";
import moment from "moment-timezone";
import {DatePicker} from "../controls/datePicker";
import {Popup} from "../controls/popup";

const yesNoBtnSize = 60;

const buttonStyle = {
  width: yesNoBtnSize,
  height: yesNoBtnSize,
  borderRadius: yesNoBtnSize,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
};

const yesIcon = {
  size: yesNoBtnSize * 0.8,
  color: "white"
};

const noIcon = {
  size: yesNoBtnSize * 0.8,
  color: palette.$accent1Shade2
};


const style = StyleSheet.create({
  section: {
    margin: 10,
    textAlign: "center",
    fontSize: 16
  },
  buttonPanel: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  confirmationMsg: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "700",
    color: "white"
  },
  yesButton: {
    ...buttonStyle,
    backgroundColor: palette.$accent1Shade2
  },
  noButton: {
    ...buttonStyle,
    backgroundColor: "white"
  },
  datePicker: {
    minWidth: 250,
  },
  pastMeetingsPlaceholder: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
    borderTopWidth: 1,
    borderTopColor: palette.$accent1Tint1,
    padding: 5
  },
  pastMeetingsPlaceholderText: {
    fontSize: 18,
    color: palette.$accent1,
  },
});

const datePickerStyle = {
  dateInput: {
    borderColor: palette.$accent1Shade1,
    borderRadius: 6,
    backgroundColor: palette.$accent1Shade3,
    borderWidth: 1,
    padding: 6,
  },
  dateText: {
    fontSize: 20,
    color: "white"
  }
};


export function CourseDetails({route, navigation}) {
  const [course, setCourse] = useState({});
  const [showReschedulingConfirmation, setShowReschedulingConfirmation] = useState(false);
  const [showDatePopup, setShowDatePopup] = useState(false);
  const [showPastMeetings, setShowPastMeetings] = useState(false);
  const [rescheduling, setRescheduling] = useState(false);
  const [newScheduleStartingDate, setNewScheduleStartingDate] = useState(moment());

  useEffect(() => {
    const c = route.params.course;
    c.meetings.forEach(m =>{
      const course = {...c}
      delete course.meetings
      m.course = course
    });
    setCourse(c);
    navigation.setOptions({ title: c.name });
  }, []);

  return (
    <>
    <ScrollView>
      <Schedule schedule={course.schedule}
                tz={course.timezone}
                onChange={s => {setCourse({...course, schedule: s}); setShowDatePopup(true)} }/>
      {!showPastMeetings &&
        <TouchableOpacity style={style.pastMeetingsPlaceholder} onPress={() => setShowPastMeetings(true)}>
          <Entypo name="eye" size={30} color={palette.$accent1}/>
          <Text style={style.pastMeetingsPlaceholderText}>  Show past meetings</Text>
        </TouchableOpacity>}
      {course.meetings && course.meetings
        .filter(m => (showPastMeetings && m.state === "TAUGHT") || m.state === "PLANNED")
        .sort((m1, m2) => !m1.startTime ? 1 : m1.startTime > m2.startTime ? 1 : -1)                     //sort by date, push floating meetings to the end of the list
        .map(m => <MeetingItem key={m._id} meeting={m}/>)}
    </ScrollView>
    {showDatePopup &&
      <Popup>
        <Text style={style.confirmationMsg}>When does the new schedule start?</Text>
        <DatePicker
          style={style.datePicker}
          date={moment.utc(newScheduleStartingDate)}
          mode="date"
          format="DD-MM-YYYY"
          confirmBtnText="Confirm"
          cancelBtnText="Cancel"
          customStyles={datePickerStyle}
          onDateChange={dateStr => setNewScheduleStartingDate(moment(dateStr, "DD-MM-YYYY"))}
        />
        <View style={style.buttonPanel}>
          <TouchableOpacity style={style.noButton} onPress={() => setShowDatePopup(false)}>
            <Entypo name="cross" size={noIcon.size} color={noIcon.color}/>
          </TouchableOpacity>
          <TouchableOpacity style={style.yesButton} onPress={newScheduleDateChosen}>
            <Entypo name="check" size={yesIcon.size} color={yesIcon.color}/>
          </TouchableOpacity>
        </View>
      </Popup>
    }
    {showReschedulingConfirmation &&
      <Popup>
        <Text style={style.confirmationMsg}>You're about to reschedule all meetings starting from {newScheduleStartingDate.format("DD MMM YY")}</Text>
        <Text style={style.confirmationMsg}>Are you sure you want to continue?</Text>
        <View style={style.buttonPanel}>
          <TouchableOpacity style={style.noButton} onPress={() => setShowReschedulingConfirmation(false)}>
            <Entypo name="cross" size={noIcon.size} color={noIcon.color}/>
          </TouchableOpacity>
          <TouchableOpacity style={style.yesButton} onPress={() => !rescheduling && reschedulePlanned()}>
            <Entypo name="check" size={yesIcon.size} color={yesIcon.color}/>
          </TouchableOpacity>
        </View>
      </Popup>
    }
    </>
  );

  function newScheduleDateChosen() {
    setShowDatePopup(false);
    setShowReschedulingConfirmation(true);
  }

  async function reschedulePlanned() {
    setRescheduling(true);
    try {
      await saveChanges();
      await meetingApi.reschedulePlannedMeetings(course._id, newScheduleStartingDate);
      const c = await courseApi.getCourse(course._id);
      c.meetings.forEach(m => m.course = c);
      setCourse(c);
      setShowReschedulingConfirmation(false);
    } catch (e) {
      console.error(e);
    }
    setRescheduling(false);
  }

  async function saveChanges() {
    try {
      const returnedCourse = await courseApi.updateCourse(course);
      returnedCourse.meetings.forEach(m => m.course = returnedCourse);
      setCourse(returnedCourse);
    } catch (e) {
      console.error(e);
    }
  }
}