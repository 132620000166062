import {api} from "./api";

export const vocabularyApi = {
  updatePair: (newPair) => {
    return api.vocabSvcRequest('PUT', `/pairs/${newPair._id}`, newPair);
  },
  addPair: (newPair) => {
    return api.vocabSvcRequest('POST', `/pairs`, newPair);
  },
  searchPairs: async (userIds, searchStr) => {
    return api.vocabSvcRequest('GET', `/pairs?contains=${searchStr}&userIds=${userIds.join(',')}`);
  }
};