import Bugsnag from "./Bugsnag";
import React from "react";
import { Platform } from "react-native";

if (Platform.OS !== "web") {
  Bugsnag.start();
}

export const LoggerWrapperComponent = Platform.OS !== "web" ? Bugsnag.getPlugin('react').createErrorBoundary(React) : ({children}) => <>{children}</>;

export const log = {};

if (Platform.OS === "web" || __DEV__ ) {
  log.debug = (error, extra) => {
    console.log(error, extra);
  };
  log.info = (error, extra) => {
    console.log(error, extra);
  };
  log.warning = (error, extra) => {
    console.error(error, extra);
  };
  log.error = (error, extra) => {
    console.error(error, extra);
  };
  log.updateUser = (userId) => {
  }
} else {
  log.debug = (error, extra) => {
    console.debug(error, extra);
  };
  log.info = (error, extra) => {
    console.log(error, extra);
  };
  log.warning = (error, extra) => {
    console.warn(error, extra);
    Bugsnag.notify(new Error(error, {cause: extra}));
  };
  log.error = (error, extra) => {
    console.error(error, extra);
    Bugsnag.notify(new Error(error, {cause: extra}));
  };
  log.updateUser = (userId) => {
    Bugsnag.setUser(userId);
  };
}
