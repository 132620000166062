import {View, StyleSheet} from "react-native";
import React from "react";
import {palette} from "../style/palette";

const style = StyleSheet.create({
  shadow: {
    position: "absolute",
    backgroundColor: "#ffffffa0",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center"
  },
  popup: {
    flexShrink: 0,
    marginHorizontal: 20,
    marginVertical: 60,
    borderRadius: 20,
    maxWidth: 600,
    backgroundColor: palette.$accent1Shade3,
    alignItems: "stretch",
    justifyContent: "space-around",
    paddingVertical: 20,
    paddingHorizontal: 35
  },
})

export function Popup({children, backdropStyle, dialogStyle}) {
  return (
  <View style={[style.shadow, backdropStyle]}>
    <View style={[style.popup, dialogStyle]}>
      {children}
    </View>
  </View>
  );
}