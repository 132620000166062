import {Popup} from "./popup";
import {Text, TouchableOpacity, View, StyleSheet} from "react-native";
import React, {useState} from "react";
import {Entypo} from "@expo/vector-icons";
import {palette} from "../style/palette";
import {Spinner} from "./spinner";

const yesNoBtnSize = 60;

const buttonStyle = {
  width: yesNoBtnSize,
  height: yesNoBtnSize,
  borderRadius: yesNoBtnSize,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
};
const yesIcon = {
  size: yesNoBtnSize * 0.8,
  color: "white",
};

const noIcon = {
  size: yesNoBtnSize * 0.8,
  color: "white",
};

const style = StyleSheet.create({
  buttonPanel: {
    flexDirection: "row",
    justifyContent: "space-around",
    paddingVertical: 30,
  },
  confirmationMsg: {
    textAlign: "center",
    fontSize: 18,
    fontWeight: "700",
    color: palette.$accent1Shade2,
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  yesButton: {
    ...buttonStyle,
    backgroundColor: palette.$accent1Shade2,
  },
  noButton: {
    ...buttonStyle,
    backgroundColor: palette.$accent1Shade2,
  },
  backdropStyle: {
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  },
  dialogStyle: {
    backgroundColor: "white",
  }
});


export function ConfirmationDialog({message, onConfirm, onCancel}) {
  const [confirming, setConfirming] = useState(false);

  return (
    <Popup backdropStyle={style.backdropStyle} dialogStyle={style.dialogStyle}>
      <Text style={style.confirmationMsg}>{message}</Text>
      <View style={style.buttonPanel}>
        <TouchableOpacity style={style.noButton} onPress={onCancel}>
          <Entypo name="cross" size={noIcon.size} color={noIcon.color}/>
        </TouchableOpacity>
        <TouchableOpacity style={style.yesButton} onPress={!confirming && confirmedPressed}>
          {!confirming && <Entypo name="check" size={yesIcon.size} color={yesIcon.color}/>}
          {confirming && <Spinner color="white"/>}
        </TouchableOpacity>
      </View>
    </Popup>
  );

  async function confirmedPressed() {
    setConfirming(true);
    try {
      await onConfirm();
    } finally {
      setConfirming(false);
    }
  }
}