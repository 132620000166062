export const palette = {
  $accent1Tint2: "#e6effc",
  $accent1Tint1: "#bedbff",
  $accent1: "#a5c0e5",
  $accent1Shade1: "#84a5d1",
  $accent1Shade2: "#455d89",
  $accent1Shade3: "#2d3037",

  $grey: "#DDDDDD",
  $greyText: "#919191"
};