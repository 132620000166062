import React from 'react';
import moment from "moment";
import {StyleSheet, Text, View, TouchableOpacity} from 'react-native';
import {palette} from "../style/palette";
import {subjectHumanReadableNames} from "../courses/subjectNames";

const styles = StyleSheet.create({
  meeting: {
    width: "100%",
    flexShrink: 1,
    padding: 10
  },
  duration: {
    fontSize: 10,
    paddingBottom: 1
  },
  name: {
    fontSize: 20,
  },
  subject: {
    fontSize: 12,
    textTransform: "uppercase",
    marginBottom: 2
  }
});

const mStyles = {
  TAUGHT: {
    titleCol: palette.$accent1,
    subtitleCol: palette.$accent1,
  },
  PLANNED: {
    titleCol: palette.$accent1Shade3,
    subtitleCol: palette.$accent1Shade2,
    locationCol: palette.$accent1
  },
  RESCHEDULED: {
    titleCol: "#fc7",
    subtitleCol: "#fc7",
    textDecorationLine: "line-through"
  },
  late: {
    titleCol: "#ff0000",
    subtitleCol: palette.$accent1Shade2,
  }
};

export class Meeting extends React.Component {
  render() {
    const m = this.props.meeting;
    const late = isPastMeeting(m) && m.state === 'PLANNED';
    const course = m.course;
    if (!!m) {
      let mSt;
      mSt = late ? mStyles.late : (mStyles[m.state] || mStyles.PLANNED);
      return (
        <TouchableOpacity onPress={this.props.onPress} style={styles.meeting}>
          <Text style={[styles.subject, {color: mSt.subtitleCol, textDecorationLine: mSt.textDecorationLine}]}>
            {subjectHumanReadableNames[course.subject]}
          </Text>
          <Text style={[styles.name, {color: mSt.titleCol, textDecorationLine: mSt.textDecorationLine}]}>
            {m.course.name}
          </Text>
          <View style={{flexDirection: "row", alignItems: "flex-end"}}>
            <Text style={{color: mSt.subtitleCol, textDecorationLine: mSt.textDecorationLine}}>
              {formatTime(m.startTime)}
            </Text>
            <Text style={[styles.duration, {color: mSt.subtitleCol}]}>   {m.durationMins} mins</Text>
          </View>
          {!late && m.state === 'PLANNED' && m.enrolledStudents && m.enrolledStudents.length > 1 &&
            <Text style={{color: mSt.locationCol}}>{m.enrolledStudents.map(s => s.name).join(', ')}</Text>
          }
        </TouchableOpacity>
      );
    } else {
      return [];
    }
  }
}

function formatTime(time) {
  if (!time) return "-";
  const mmnt = moment(time);
  if (isToday(time))
    return mmnt.format("HH:mm");
  else
    return mmnt.format("DD MMMM HH:mm");
}

function isToday(time) {
  moment(time).isSame(moment(), 'date');
}

function isPastMeeting(m) {
  return m.startTime && moment(m.startTime).isBefore();
}