import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {MeetingPanel} from "./meetingPanel";
import {Entypo} from "@expo/vector-icons";
import {palette} from "../style/palette";
import history from "../history/history";
import {meetingApi} from "../api/meetingApi";

const style = StyleSheet.create({
  noShowContainer: {
    flex: 1,
    justifyContent: "center"
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: palette.$accent1Shade2,
    marginVertical: 10,
  },
  confirm: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  label:{
    textAlign: "center"
  },
  option: {
    alignItems: "center",
    width: 100,
    margin: 10
  }
});

const icon = {
  size: 60,
  color: palette.$accent1Shade2,
  disabledColor: palette.$accent1Tint1,
};

export class NoShowReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false
    };
    this.submitNoShowReport = this.submitNoShowReport.bind(this);
  }

  async submitNoShowReport() {
    this.setState({isSubmitting: true});
    const meeting = this.props.route.params.meeting;
    try {
      const meetingReported = await meetingApi.reportMeeting({
        meetingId: meeting._id,
        subject: "No show",
        studentsAttention: 5,
        studentsParticipation: 5,
        studentsPerformance: 5,
        comments: "",
        attended: []
      });
      this.setState({isSubmitting: false});
      if (meetingReported) {
        history().push("/meetingList");
        return;
      }
    } catch (e) {
      console.error(e);
    }
    this.setState({isSubmitting: false});
  }

  render() {
    const meeting = this.props.route.params && this.props.route.params.meeting;
    const st = this.state;
    return (
      <View style={style.noShowContainer}>
        <MeetingPanel meeting={meeting}/>
        <View>
          <Text style={style.title}>No one showed up?</Text>
        </View>
        <View style={style.confirm}>
          <View style={style.option}>
            <Entypo name="arrow-left" onPress={history().goBack} size={icon.size} color={icon.color}/>
            <Text style={style.label}>oh, wait, someone did</Text>
          </View>
          <View style={style.option}>
            <Entypo name="emoji-sad"
                    onPress={st.isSubmitting ? () => {} : this.submitNoShowReport}
                    size={icon.size}
                    color={st.isSubmitting ? icon.disabledColor : icon.color}/>
            <Text style={style.label}>No, no one</Text>
          </View>
        </View>
      </View>
    );
  }
}

