import { log, LoggerWrapperComponent } from './src/logger/logger';
import React, {useEffect, useRef, useState} from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
import {EmailLogin} from "./src/login/emailLogin";
import {navigationRef} from "./src/history/history";
import {ReportEditor} from "./src/meetings/reportEditor";
import {MeetingsFeed} from "./src/meetings/meetingsFeed";
import {palette} from "./src/style/palette";
import {PasswordChange} from "./src/account/passwordChange";
import {MaterialIcons, MaterialCommunityIcons, Entypo, FontAwesome} from "@expo/vector-icons";
import * as Font from 'expo-font'
import {MeetingRescheduler} from "./src/meetings/meetingRescheduler";
import {MeetingEditor} from "./src/meetings/meetingEditor";
import {AttendanceReport} from "./src/meetings/attendanceReport"
import {NoShowReport} from "./src/meetings/noShowReport";
import moment from "moment-timezone";
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {VocabularyEditor} from "./src/vocabulary/vocabularyEditor";
import {CourseProposals} from "./src/courseProposals/courseProposals";
import {CourseProposalDetails} from "./src/courseProposals/courseProposalDetails";
import {pushNotifications} from "./src/expo/push/pushNotifications";
import {Courses} from "./src/courses/courses";
import {CourseDetails} from "./src/courses/courseDetails";
import {ReportViewer} from "./src/meetings/reportViewer";
import {StatusBar} from 'expo-status-bar';
import { Account } from './src/account/account';
import { PayslipViewer } from './src/payslips/payslipViewer';
import { Payslips } from './src/payslips/payslips';
import {openLink} from './src/controls/linking';
import { userSession } from './src/api/userSession';
import {chatSession} from "cerah-chat";
import {chatApi} from './src/api/chatApi';
import {ChatScreen} from './src/chat/chatScreen';
import * as SplashScreen from 'expo-splash-screen';

const headerBarOptions = {
  headerStyle: {
    backgroundColor: __DEV__ ? "#882" : palette.$accent1Shade2,
  },
  headerTintColor: '#fff',
};

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default () => <LoggerWrapperComponent>
  <App />
</LoggerWrapperComponent>

console.log("__DEV__", __DEV__);

SplashScreen.preventAutoHideAsync();

function App (props) {
  const [appIsReady, setAppIsReady] = useState(false);
  const unsusbscribeFromNotifications = useRef(null);
  
  moment().isSame(moment(), "date");    //DO NOT REMOVE THIS LINE: it seems to reset the current Date.getTimezoneOffset() to the correct value

  useEffect(() => {
    Promise.all([
      Font.loadAsync(Entypo.font),
      Font.loadAsync(MaterialCommunityIcons.font)
    ]).catch(e => {
      log.error("Failed to load fonts", e);
    });
    pushNotifications.init().then(unsubscribeFromNotifications => {
      unsusbscribeFromNotifications.current = unsubscribeFromNotifications;
    }).catch(e => {
      log.error("Failed to initialize push notifications", e);
    });
    setAppIsReady(true);
    return () => {
      (unsusbscribeFromNotifications.current)();
    }
  }, []);
  
  if (!appIsReady) {
    return null;
  }

  return (
    <NavigationContainer ref={navigationRef} onReady={SplashScreen.hideAsync}>
      <View style={{backgroundColor: "white", flex: 1}}>
        <StatusBar style="light" backgroundColor={palette.$accent1Shade2} translucent={false}/>
        <LoginStackScreen />
      </View>
    </NavigationContainer>
  );
}


function LoginStackScreen(props) {
  return (
    <Stack.Navigator initialRouteName="/">
      <Stack.Screen name="/login" component={EmailLogin} options={{header: () => null}}/>
      <Stack.Screen name="/" component={AppTabScreen} options={{header: () => null}}/>
    </Stack.Navigator>
  );
}

function AppTabScreen(props) {
  const [unreadCount, setUnreadCount] = useState(null);
  
  useEffect(() => {
    loadUnreadCount();
  }, []);
  
  async function loadUnreadCount() {
    let userToken =  await userSession.getToken();
    if (userToken) {
      try {
        await chatSession.login(await chatApi.getTeachersForumRoomId(), await userSession.getToken(), (...args) => log.error(...args));
      } catch (e) {
        log.error("Couldn't log into rocket chat", e);
      }
      chatSession.addMessageListener(() => {
        let unreadMsgCount = chatSession.getUnreadMessagesCount();
        if (unreadMsgCount > 99) unreadMsgCount = "99+";
        setUnreadCount(unreadMsgCount ? unreadMsgCount : null);
      });
      setUnreadCount(chatSession.getUnreadMessagesCount() || null);
    }
  }

  return (
    <Tab.Navigator initialRouteName="/meetings"
                   tabBarOptions={{
                     keyboardHidesTabBar: true,
                     activeTintColor: palette.$accent1Shade2,
                     inactiveTintColor: "black",
                     labelStyle: {
                       fontWeight: "700"
                     },
                     style: {
                       paddingTop: 10,
                       minHeight: 56,
                     }
                   }}>
      <Tab.Screen name="/meetings"
                  component={MeetingsStackScreen}
                  options={{
                    tabBarIcon: TabIcon("check"),
                    title: "Meetings"
                  }}/>
      <Tab.Screen name="/courses"
                  component={CoursesStackScreen}
                  options={{
                    tabBarIcon: TabIcon("graduation-cap"),
                    title: "Courses"
                  }}/>
      <Tab.Screen name="/courseProposals"
                  component={CourseProposalsStackScreen}
                  options={{
                    tabBarIcon: TabIcon("notifications-active"),
                    title: "Proposals",
                    header: () => null
                  }}/>
      <Tab.Screen name="/account"
                  component={AccountStackScreen}
                  options={{
                    tabBarIcon: TabIcon("account"),
                    title: "Account",
                    header: () => null
                  }}/>
      <Tab.Screen name="Chat"
                  component={ChatScreen}
                  options={{
                    tabBarBadge: unreadCount,
                    tabBarIcon: ({color, size}) =>
                      <MaterialCommunityIcons name="chat" size={size} color={color}/>,
                    title: "Chat"
                  }}/>
    </Tab.Navigator>
  );
}


function commonScreensToMeetingsAndCoursesStack() {
  return (<>
    <Stack.Screen name="/meetings/attendance" component={AttendanceReport} options={{title: "Attendance"}}/>
    <Stack.Screen name="/meetings/noShow" component={NoShowReport} options={{title: "No show"}}/>
    <Stack.Screen name="/meetings/report" component={ReportEditor} options={{title: "Report"}}/>
    <Stack.Screen name="/meetings/reschedule" component={MeetingEditor} options={{title: "Reschedule"}}/>
    <Stack.Screen name="/meetings/rescheduleToDate" component={MeetingRescheduler} options={{title: "Reschedule"}}/>
    <Stack.Screen name="/courses/vocabulary" component={VocabularyEditor} options={{title: "Vocabulary"}}/>
  </>);
}

function MeetingsStackScreen(props) {
  //do not remove the title attribute in each Screen element as it's needed for webpages
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="/meetingList" component={MeetingsFeed} options={{title: "Meetings", headerTitle: () => <StackScreenHeader title="Meetings"/>}}/>
      {commonScreensToMeetingsAndCoursesStack()}
    </Stack.Navigator>
  );
}

function AccountStackScreen(props) {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="/account" component={Account} options={{title: "Account", headerTitle: () => <StackScreenHeader title="Account"/>}}/>
      <Stack.Screen name="/passwordChange" component={PasswordChange} options={{title: "Change your password"}}/>
      <Stack.Screen name="/payslips" component={Payslips} options={{title: "Payslips"}}/>
      <Stack.Screen name="/payslipViewer" component={PayslipViewer} options={{title: "Payslip"}}/>
    </Stack.Navigator>
  );
}


function CoursesStackScreen(props) {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="/coursesList" component={Courses} options={{title: "Courses", headerTitle: () => <StackScreenHeader title="Courses"/>}}/>
      <Stack.Screen name="/courses/details" component={CourseDetails} options={{title: "Course"}}/>
      <Stack.Screen name="/meetings/previousReport" component={ReportViewer} options={{title: "Report"}}/>
      {commonScreensToMeetingsAndCoursesStack()}
    </Stack.Navigator>
  );
}


function CourseProposalsStackScreen(props) {
  return (
    <Stack.Navigator
      screenOptions={headerBarOptions}
    >
      <Stack.Screen name="/courseProposalsList" component={CourseProposals} options={{title: "Course proposals", headerTitle: () => <StackScreenHeader title="Course proposals"/>}}/>
      <Stack.Screen name="/courseProposals/details" component={CourseProposalDetails} options={{title: "Proposal Details", header: () => null}}/>
    </Stack.Navigator>
  )
}

function TabIcon(name) {
  let Icon;
  switch (name) {
    case "check":
      Icon = (p) => <Entypo {...p}/>; break;
    case "graduation-cap":
      Icon = (p) => <Entypo {...p}/>; break;
    case "account":
      Icon = (p) => <MaterialCommunityIcons {...p}/>; break;
    case "notifications-active":
      Icon = (p) => <MaterialIcons {...p}/>; break;
    case "power-off":
      Icon = (p) => <FontAwesome {...p}/>; break;
  }
  return ({color}) => <Icon name={name} size={30} color={color}/>
}

function StackScreenHeader({title}) {
  return (
    <View style={{flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
      <Text style={{fontSize: 18, color: "#fff"}}>
        {title}
      </Text>
      <Pressable
        style={{flexDirection: "row", alignItems: "center"}}
        onPress={() => {
          openLink("https://www.canva.com/design/DAEk5gccaVQ/OKYgOi-RLqLVBsRh9a-rvg/view")
        }}>
        <FontAwesome style={{marginBottom: Platform.OS === "web" ? 3 : 0}} name="question-circle" size={36} color="white" />
      </Pressable>
    </View>
  )
}