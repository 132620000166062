import React from 'react';
import history from "../history/history";
import {StyleSheet, Button, View, ScrollView, Text, TouchableOpacity, TouchableWithoutFeedback} from 'react-native';
import {palette} from "../style/palette";
import {courseApi} from "../api/courseApi";
import {Spinner} from "../controls/spinner";
import {Entypo, MaterialCommunityIcons} from "@expo/vector-icons";
import {MeetingPanel} from "./meetingPanel";
import moment from "moment-timezone";

const style = StyleSheet.create({
  attendanceReport: {
    flexGrow: 1,
    flexShrink: 0
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: palette.$accent1Shade2,
    marginVertical: 10,
  },
  studentEntry: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
    marginHorizontal: 10,
    padding: 5
  },
  studentName: {
    fontSize: 18,
    color: palette.$accent1Shade1,
    marginLeft: 10
  },
  attendedEntry: {
    backgroundColor: palette.$accent1Shade2,
  },
  attendedStudentName: {
    color: "white"
  },
  yesNoPanel: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  label:{
    textAlign: "center"
  },
  option: {
    alignItems: "center",
    width: 100,
    margin: 10
  }
});

const icon = {
  name: "account",
  color: palette.$accent1Shade2,
  size: 44
};

const attendedIcon = {
  name: "account-check",
  color: "white",
  size: 44
};

const optionIcon = {
  size: 60,
  color: palette.$accent1Shade2
};

export class AttendanceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state={};
    if (props.route.params && props.route.params.meeting) {
      this.state.meeting = props.route.params.meeting
    } else {
      history().push("/meetingList");
    }
    this.attendeesSelected = this.attendeesSelected.bind(this);
    this.loadState();
  }

  async loadState() {
    this.setState({
      students: await courseApi.getStudents(this.state.meeting.course._id, moment(this.state.meeting.startTime).valueOf())
    });
  }

  render() {
    const students = this.state.students;
    return (
      <View style={style.attendanceReport}>
        <MeetingPanel meeting={this.state.meeting}/>
        {
          students ?
            (students.length > 1 ?
                <AttendanceReportGroup students={students}
                                       onCompleted={this.attendeesSelected}/> :
                <AttendanceReportIndividual students={students}
                                            onCompleted={this.attendeesSelected}/>
            ) :
            <View/>
        }
      </View>
    );
  }

  async attendeesSelected(attendees) {
    const st = this.state;
    if (attendees.length === 0) {
      history().push(`/meetings/noShow`, {meeting: st.meeting});
      return;
    }
    history().push(`/meetings/report`, {
      meeting: st.meeting,
      attended: attendees
    });
  }
}

class AttendanceReportGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attended: {}
    }
  }

  render() {
    const students = this.props.students;
    const attended = this.state.attended;
    //the view around ScrollView is needed bcs ScrollView doesn't support flexGrow properly
    return (
      <>
        <View>
          <Text style={style.title}>Who attended?</Text>
        </View>
        <View style={{flex: 1}}>
          <ScrollView>
            {!students.length && <Spinner/>}
            {students.map(s => {
              const ic = attended[s._id] ? attendedIcon : icon;
              return (
                <TouchableOpacity style={[style.studentEntry, attended[s._id] ? style.attendedEntry : null]}
                                  key={s._id}
                                  onPress={() => this.flipAttendance(s._id)}
                                  activeOpacity={1}
                                  onPressDelay={100}>
                  <MaterialCommunityIcons {...ic}/>
                  <Text style={[style.studentName, attended[s._id] ? style.attendedStudentName : null]}>{s.name}</Text>
                </TouchableOpacity>
              )
            })}
          </ScrollView>
        </View>
        <Button onPress={() => this.props.onCompleted(Object.entries(attended).filter(([k, value]) => value).map(([key, v]) => key))}
                title={this.numAttendees() === 0 ? "No one" : "Next"}
                color={palette.$accent1Shade2}/>
      </>
    );
  }

  async flipAttendance(studentId) {
    this.setState((curSt) => {return {
      attended: {
        ...curSt.attended,
        [studentId]: !curSt.attended[studentId]
      }
    }});
  }

  numAttendees() {
    return Object.values(this.state.attended).filter(attendance => attendance).length;
  }
}

function AttendanceReportIndividual(props) {
  return (
    <>
    <View>
      <Text style={style.title}>Did the student(s) attend?</Text>
    </View>
    <View style={style.yesNoPanel}>
      <View style={style.option}>
        <Entypo name="emoji-sad"
                size={optionIcon.size}
                color={optionIcon.color}
                onPress={() => props.onCompleted([])}>
        </Entypo>
        <Text style={style.optionLabel}>NO</Text>
      </View>
      <View style={style.option}>
        <Entypo name="check"
                size={optionIcon.size}
                color={optionIcon.color}
                onPress={() => props.onCompleted(props.students.map(s => s._id))}>
        </Entypo>
        <Text style={style.optionLabel}>YES</Text>
      </View>
    </View>
    </>
  )
}