import {api} from "./api";

export const classRequestApi = {
  getCourseProposals: async () => {
    return api.classRequestSvcRequest('GET', `/my/courseProposals`);
  },

  getCourseProposal: async (courseProposalId) => {
    return api.classRequestSvcRequest('GET', `/my/courseProposals/${courseProposalId}`);
  },

  rejectCourseProposal: async (courseProposalId) => {
    return api.classRequestSvcRequest('POST', `/my/teacherRejections`, {courseProposalId});
  },

  acceptCourseProposal: async (courseProposalId) => {
    return api.classRequestSvcRequest('POST', `/my/teacherAcceptances`, {courseProposalId/*, conditions*/});
  },
};