import React, { useState } from 'react';
import {Meeting} from "./meeting";
import {StyleSheet, Modal, TouchableOpacity, View, Text, Platform} from 'react-native';
import {MaterialCommunityIcons, Entypo} from "@expo/vector-icons";
import history from '../history/history';
import moment from "moment-timezone";
import {palette} from "../style/palette";
import {userSession} from "../api/userSession";
import {openLink} from '../controls/linking';

const style = StyleSheet.create({
  callButton: {
    backgroundColor: palette.$accent1Shade2,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    borderRadius: 10,
    elevation: 10,
    margin: 10,
    maxWidth: "80%",
    width: 300,
    alignSelf: "center"
  },
  callButtonLabel: {
    color: "#fff",
    fontSize: 18,
    marginLeft: 10,
  },
  meetingTile: {
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
  },
  meetingItem: {
    flexDirection: "row",
  },
  iconsView: {
    flexShrink: 0, flexDirection: "row", alignItems: "center"
  },
  centeredView: {
    top: "10%",
    left: 0,
    right: 0,
    position: "absolute",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  customButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 30,
    paddingHorizontal: 16,
  },
  customButtonText: {
    paddingVertical: 10,
    paddingHorizontal: 10,
    fontSize: 14,
    fontWeight: "bold"
  },
  contactTile: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
    width: "100%",
    backgroundColor: palette.$accent1Tint2,
    borderRadius: 10
  },
  contactTileText: {
    fontSize: 17,
    fontWeight: "800"
  }
});

const icon = {
  size: 32,
  color: palette.$accent1Shade2,
  colorTaught: palette.$accent1,
};

export class MeetingItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {showContactModal: false};
  }

  render() {
    const m = this.props.meeting;
    const late = isPastMeeting(m) && m.state === 'PLANNED';
    const taught = (m.state === 'TAUGHT');
    return (
      <View style={style.meetingTile}>
        <View style={style.meetingItem}>
          {m.enrolledStudents && <WhatsAppContactDialogue modalVisible={this.state.showContactModal} enrolledStudents={m.enrolledStudents} closeModal={() => this.setState({showContactModal: false})} />}
          <Meeting key={m._id} meeting={m} onPress={report}/>
          <View style={style.iconsView}>
            <MaterialCommunityIcons onPress={() => {
              if (m.enrolledStudents.length === 1)
                openLink(`https://wa.me/${m.enrolledStudents[0].phone}`);
              else
                this.setState({showContactModal: true});
            }} name="whatsapp" size={icon.size} color={icon.color} />
            <View style={{ width: 10 }} />
            {taught && <>
              <Entypo name="text-document" onPress={seeReport} size={icon.size} color={icon.colorTaught}/>
              <View style={{width: 10}}/>
            </>}
            {m.state === "PLANNED" && <>
              <Entypo name="calendar" onPress={reschedule} size={icon.size} color={icon.color}/>
              <View style={{width: 10}}/>
            </>}
            {late && <>
              <MaterialCommunityIcons name="human-male-board" onPress={report} size={icon.size} color={icon.color}/>
              <View style={{width: 10}}/>
            </>}
            {<Entypo name="book" onPress={editVocabulary} size={icon.size} color={taught ? icon.colorTaught : icon.color}/>}
            <View style={{width: 10}}/>
            <View style={{width: 10}}/>
          </View>
        </View>
        {shouldShowCallButton(m) &&
          <TouchableOpacity style={style.callButton} onPress={onCall}>
            <MaterialCommunityIcons name="video" size={icon.size} color={"white"}/>
            <Text style={style.callButtonLabel}>Start</Text>
          </TouchableOpacity>
        }
      </View>
    );

    function reschedule() { rescheduleMeeting(m); }

    function report() { late && reportMeeting(m); }

    function editVocabulary() { editCourseVocabulary(m); }

    function seeReport() { seeMeetingReport(m); }

    async function onCall() {
      const link = `${m.videoCallLink}?name=${await userSession.getName()}`;
      if (Platform.OS === "web")
        window.open(link, '_blank')
      else
        openLink(link);
    }

  }
}

function reportMeeting(meeting) {
  history().push(`/meetings/attendance`, {meeting});
}

function rescheduleMeeting(meeting) {
  history().push(`/meetings/reschedule`, {meeting});
}

function seeMeetingReport(meeting) {
  const m = {...meeting};
  delete m.course.meetings;
  history().push(`/meetings/previousReport`, {meeting: m});
}

function editCourseVocabulary(meeting) {
  history().push(`/courses/vocabulary`, {meeting});
}

function isPastMeeting(m) {
  return m.startTime && moment(m.startTime).isBefore();
}

function shouldShowCallButton(m) {
  return (isCallTime(m) && m.videoCallLink && m.state === "PLANNED")
}

function isCallTime(m) {
  const timeToStart = m.startTime && moment(m.startTime).diff(moment(), 'minutes');
  return timeToStart < 15 && timeToStart > -m.durationMins;
}

export function WhatsAppContactDialogue({ modalVisible, enrolledStudents, closeModal }) {

  return (
    <View style={style.centeredView}>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        backdropOpacity={0.5}
      >
        <View style={style.centeredView}>
          <View style={style.modalView}>
            {enrolledStudents.map(({ name, phone }) =>
              <TouchableOpacity onPress={async () => {
                await openLink(`https://wa.me/${phone}`)
                closeModal()
              }} key={`${name}${phone}`} style={style.contactTile} >
                <View>
                  <Text style={style.contactTileText}>{name}</Text>
                  <Text style={{ fontSize: 13, color: palette.$greyText }}>{phone}</Text>
                </View>
                <MaterialCommunityIcons name="whatsapp" size={26} color={icon.color} />
              </TouchableOpacity>
            )}
            <View style={{ paddingTop: 10 }} />
            <CustomButton onPress={() => closeModal()} title={"Close"} iconColor={"white"} icon={"close-circle"} color={"white"} backgroundColor={"red"} titleColor={"white"} />
          </View>
        </View>
      </Modal>
    </View>
  )
}

export const CustomButton = ({ onPress, title, icon, iconColor, backgroundColor, titleColor }) => (
  <TouchableOpacity onPress={onPress}
    style={[style.customButtonContainer, { backgroundColor }]}>
    <MaterialCommunityIcons name={icon} size={26} color={iconColor} />
    <Text style={[style.customButtonText, { color: titleColor }]}>{title}</Text>
  </TouchableOpacity>
);
