import React, {useEffect, useState} from 'react';
import {View, Text, FlatList, StyleSheet, TouchableOpacity} from 'react-native';
import {Spinner} from "../controls/spinner";
import {palette} from "../style/palette";
import history from '../history/history';
import {courseApi} from "../api/courseApi";
import {log} from '../logger/logger';

const style = StyleSheet.create({
  noProposals: {
    padding: 20,
    fontSize: 18
  },
  header: {
    width: "100%",
    backgroundColor: palette.$accent1Tint2,
    padding: 5,
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: 14
  }
});

export function Courses(props) {
  const [courses, setCourses] = useState(null);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    loadState();
    return props.navigation.addListener('focus', loadState);
  }, []);

  async function loadState() {
    setRefreshing(true);
    try {
      setCourses((await courseApi.getActiveCourses()).sort((c1, c2) => c1.name > c2.name ? 1 : -1));
    } catch (e) {
      log.error("Failed to retrieve active courses", e);
    }
    setRefreshing(false);
  }

  const items = courses;
  /*Flex props in the view below are a workaround for bug https://github.com/necolas/react-native-web/issues/1436*/
  return (
    <View style={{ flex: 1, flexDirection: 'column' }}>
      {items ? (
        items.length > 0 ?
          <FlatList
            data={items}
            keyExtractor={c => c._id}
            renderItem={renderCourseItem}
            refreshing={!!refreshing}
            onRefresh={loadState}
          /> :
          <Text style={style.noProposals}>You have no active courses</Text>
        ) :
        <Spinner/>
      }
    </View>
  );
}

const styleCourseEntry = StyleSheet.create({
  item: {
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1,
    padding: 8,
    minHeight: 62,
  },
  name: {
    fontSize: 16,
    fontWeight: "800",
    color: palette.$accent1Shade2,
    paddingBottom: 2,
  },
});

function renderCourseItem({item: c}) {
  return (
    <TouchableOpacity style={styleCourseEntry.item} onPress={() => showCourseDetails(c)}>
      <Text style={styleCourseEntry.name}>{c.name}</Text>
    </TouchableOpacity>
  );
}



function showCourseDetails(course) {
  setTimeout(() => history().push(`/courses/details`, {course}), 0);
}