import React from 'react';
import {TouchableOpacity, View, Text, StyleSheet} from 'react-native';
import {palette} from "../style/palette";

const style = StyleSheet.create({
  opacity: {
    backgroundColor: palette.$accent1Shade2,
    alignItems: "center",
    width: "100%",
    padding: 10,
    borderRadius: 5
  },
  text: {
    color: "#fff",
    fontSize: 16,
    fontWeight: "700",
    textTransform: "uppercase"
  },
  disabled: {
    backgroundColor: palette.$grey,
  },
  textDisabled: {
    color: palette.$greyText
  }
});

export function Button(props) {
  return (
    <TouchableOpacity onPress={!props.disabled ? props.onPress : () => {}} style={[style.opacity, props.style, props.disabled && style.disabled]}>
      <Text style={[style.text, props.textStyle, props.disabled && style.textDisabled]}>{props.title}</Text>
    </TouchableOpacity>
  );
}