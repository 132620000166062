import {api} from "./api";
import {refreshApi} from "./refreshApi";
import {userSession} from "./userSession";

export const userApi = {
  login: async (creds) => {
    const cerahAuth = await api.userSvcRequest('POST', '/login', creds);
    userSession.userChanged(cerahAuth);
  },

  getUser: async () => {
    const userId = await userSession.getUserId();
    if (userId) {
      return api.userSvcRequest('GET', `/users/${userId}`);
    }
  },

  changePwd: async ({userId, curPassword, newPassword}) => {
    await api.userSvcRequest('POST', '/pwdChange', {userId, curPassword, newPassword})
  },

  updateNotificationsToken: async (token) => {
    await api.notificationSvcRequest('POST', '/notificationsTokens', token);
  },

  refreshToken: async () => {
    return await refreshApi.get('/accessToken');
  }
};
