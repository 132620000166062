import {Linking, Platform} from "react-native";

export async function openLink(link) {
  if (Platform.OS === "web") {
    window.open(link, "_blank");
    return;
  }
  const canOpenUrl = await Linking.canOpenURL(link)
  if (canOpenUrl)
    try {
      await Linking.openURL(link)
    } catch (e) {
      log.error(e)
    }
}