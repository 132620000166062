import React, { useRef, useState, useEffect } from "react";
import { FlatList, View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { palette } from "../style/palette";
import moment from "moment-timezone";
import { courseApi } from "../api/courseApi";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {log} from '../logger/logger';
import {userSession} from "../api/userSession";

const style = StyleSheet.create({
  notice: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "800",
    color: "#f00",
    padding: 4,
  },
  paidStamp: {
    position: "absolute",
    zIndex: 100,
    left: 30,
    fontWeight: "bold",
    transform: [{ rotate: '-33deg' }],
    fontSize: 24,
    color: "rgba(255, 0, 0, 128)"
  },
  payslip: {
    flex: 1,
    position: "relative",
  },
  cell: {
    borderColor: "white",
    borderWidth: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 5
  },
  courseContainer: {
    margin: 10,
    padding: 20,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#ddd',
    borderBottomWidth: 0,
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 3,
    elevation: 3,
  },
  courseTotalAmount: {
    alignItems: "flex-end",
    padding: 6,
    marginTop: 10,
  },
  courseTextLarge: {
    color: "black",
    fontSize: 15,
    fontWeight: "bold"
  },
  columnHeading: {
    borderWidth: 1,
    borderColor: "white",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
    backgroundColor: palette.$accent1Tint1,
    flexShrink: 0,
    fontSize: 12,
  },
  dateColumn: {
    width: 78,
  },
  rosterColumn: {
    flex: 1
  },
  durationColumn: {
    width: 70,
  },
  amountColumn: {
    width: 70,
  },
  rosterHeader: {
    fontSize: 11,
    marginTop: 4,
    marginBottom: 2,
    borderBottomWidth: 1,
  },
  rosterCell: {
    alignItems: "flex-start"
  },
  amountCell: {
    alignItems: "flex-end"
  },
  grandTotalPanel: {
    flexDirection: "row",
    padding: 24,
    alignItems: "center",
    justifyContent: "space-between"
  },
  grandTotalText: {
    fontSize: 20,
    fontWeight: "700",
    textAlign: "right",
  },
  acceptLabel: {
    color: palette.$accent1Shade2,
    textAlign: "center",
    fontSize: 16,
  },
  acceptButton: {
    borderRadius: 100,
    backgroundColor: palette.$accent1Shade2,
    margin: 10,
    paddingVertical: 10,
    paddingHorizontal: 30
  },
  acceptButtonLabel: {
    color: "white",
    fontSize: 18,
    fontWeight: "800",
  }
});

export function PayslipViewer({route, navigation}) {

  const [payslip, setPayslip] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [isAccepting, setAccepting] = useState(false);

  useEffect(() => {
    loadPayslip();
  }, []);

  async function loadPayslip() {
    const payslip = await courseApi.getPayslip(route.params.id);
    payslip.courses = payslip.courses.sort((c1, c2) => c1.name > c2.name ? 1 : -1);
    setPayslip(payslip);
    setIsPaid(payslip.paid);
  }

  const flatListRef = useRef();

  if (!payslip) return null;

  const isNew = !payslip.paid && !payslip.acceptedByTeacher
  return (
    <View style={style.payslip}>
      {isNew && <Text style={style.notice}>Pending review; please check and approve</Text>}
      <FlatList
        ref={flatListRef}
        data={payslip.courses}
        keyExtractor={m => m._id}
        renderItem={({ item, index }) => renderCourse({ item, index })}
        refreshing={false}
      />
      {payslip.courses && <View style={style.grandTotalPanel}>
        {isNew && <View>
          <Text style={style.acceptLabel}>
            All looking good?
          </Text>
          <TouchableOpacity style={style.acceptButton}>
            <Text style={style.acceptButtonLabel} onPress={() => setAccepting(true)}>
              ✔ Accept
            </Text>
          </TouchableOpacity>
        </View>}
        <Text style={style.grandTotalText}>
          Grand Total: IDR {sumCoursesFee(payslip.courses)}
        </Text>
        {isPaid && <Text style={style.paidStamp}>
          PAID
        </Text>}
      </View>}
      {isAccepting &&
        <ConfirmationDialog onConfirm={acceptPayslip}
                            onCancel={() => setAccepting(false)}
                            message="This will mark the payslip as accepted and trigger its payout. Are you sure?"
        />
      }
    </View>
  )

  async function acceptPayslip() {
    try {
      await courseApi.acceptPayslip(payslip);
      navigation.navigate("/payslips");
    } catch (e) {
      log.error(`Failed to accept payslip for teacher ${await userSession.getUserId()}`, e);
    }
  }
}

function renderCourse({ item: course, index }) {
  return <View style={style.courseContainer}>
    <Text style={[{ paddingBottom: 10 }, style.courseTextLarge]}>{index + 1}. {course.name}</Text>
    <View style={{ flexDirection: "row" }}>
      <View style={[style.columnHeading, style.dateColumn]}><Text>Date</Text></View>
      <View style={[style.columnHeading, style.rosterColumn]}><Text>Roster</Text></View>
      <View style={[style.columnHeading, style.durationColumn]}><Text>Duration</Text></View>
      <View style={[style.columnHeading, style.amountColumn]}><Text>Fee</Text></View>
    </View>
    <View style={{
      flexDirection: "row"
    }} key={course._id}>
      <View style={{ flex: 1 }}>
        {
          course.meetings.map(meeting => <View style={{
            flexDirection: "row"
          }} key={meeting._id}>
            <View style={[style.cell, style.dateColumn]}>
              <Text>{moment(meeting.startTime).format("DD-MMM-YY")}</Text>
              <Text>{moment(meeting.startTime).format("HH:mm")}</Text>
            </View>
            <View style={[style.cell, style.rosterColumn, style.rosterCell]}>
              <Roster meeting={meeting}/>
            </View>
            <View style={[style.cell, style.durationColumn]}>
              <Text>{meeting.durationMins} min</Text>
            </View>
            <View style={[style.cell, style.amountColumn, style.amountCell]}>
              <Text style={style.courseTextLarge} >{meeting.fee}</Text>
            </View>
          </View>)
        }
      </View>
    </View>
    <View style={style.courseTotalAmount}>
      <Text style={style.courseTextLarge}> Total: IDR {course.meetings.reduce((acc, m) => acc + m.fee, 0)}</Text>
    </View>
  </View>
}

function Roster({meeting: m}) {
  const enrollees = m.enrolledStudents;
  const enrolleeIds = enrollees.map(st => st.stId);
  const attendees = m.report.attended;
  const attendeeIds = attendees.map(st => st.stId);
  const attendeesNotEnrolledYet = attendees.filter(({stId}) => !enrolleeIds?.includes(stId));
  return <>
    {enrollees.length > 0 && <Text style={style.rosterHeader}>Paying students</Text>}
    {enrollees.map(({name, stId}) =>
      <Text key={stId} className={style.student}>{attendeeIds.includes(stId) ? "✔" : "❌"} {name}</Text>
    )}

    {attendeesNotEnrolledYet.length > 0 && <Text style={style.rosterHeader}>Free trial students</Text>}
    {attendeesNotEnrolledYet.map(({name, stId}) =>
      <Text key={stId}>✔ {name}</Text>
    )}
  </>
}

function sumCourseFee(course) {
  return course.meetings.reduce((acc, m) => acc + m.fee, 0);
}

function sumCoursesFee(courses) {
  return courses.reduce((acc, c) => acc + sumCourseFee(c), 0);
}

