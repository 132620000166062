import React from 'react';
import {Meeting} from "./meeting";
import history from "../history/history";
import {StyleSheet, Button, View, ScrollView, Text, TextInput, KeyboardAvoidingView} from 'react-native';
import RNCSlider from '@react-native-community/slider';
import {meetingApi} from "../api/meetingApi";
import {palette} from "../style/palette";
import Toast from 'react-native-toast-message';

const style = StyleSheet.create({
  classReport: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 10
  },
  textInput: {
    minHeight: 80,
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10
  },
  slider: {
    padding: 20
  }
});

export class ReportEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      isSubmitting: false,
      studentsAttention: 50,
      studentsParticipation: 50,
      studentsPerformance: 50
    };
    this.submitClicked = this.submitClicked.bind(this);
    if (props.route.params && props.route.params.meeting) {
      this.state.meeting = props.route.params.meeting
    } else {
      history().push("/meetingList");
    }
  }

  render() {
    //the view around ScrollView is needed bcs ScrollView doesn't support flexGrow properly
    const st = this.state;
    return (
      <KeyboardAvoidingView style={{flex: 1}} behavior="height">
        <View style={style.classReport}>
          <ScrollView>
            <Meeting meeting={st.meeting}/>
            {st.meeting &&
              <View>
                <CommentsInput
                           value={st.subject}
                           onChangeText={this.formChanged("subject")}
                           placeholder="Material covered"/>
                <Text>Students' attention</Text>
                {this.renderSlider("studentsAttention")}
                <Text>Students' participation</Text>
                {this.renderSlider("studentsParticipation")}
                <Text>Performance on exercises or practices</Text>
                {this.renderSlider("studentsPerformance")}
                <CommentsInput
                  value={st.comments}
                  onChangeText={this.formChanged("comments")}
                  placeholder="Any comments?"
                />
              </View>
            }
          </ScrollView>
        </View>
        <Button onPress={st.isSubmitting ? () => {} : this.submitClicked}
                title="Submit"
                color={st.isSubmitting ? palette.$accent1Tint2 : palette.$accent1Shade2}/>
        <Toast/>
      </KeyboardAvoidingView>
    );
  }

  renderSlider(fieldName) {
    return (
      <RNCSlider style={style.slider}
              minimumValue={0}
              maximumValue={100}
              value={50}
              onValueChange={this.formChanged(fieldName)}
      />
    );
  }

  formChanged(fieldName) {
    return (value) => {
      this.setState({
        [fieldName]: value
      });
    }
  }

  async submitClicked() {
    this.setState({isSubmitting: true});
    const st = this.state;
    try {
      const meetingReported = await meetingApi.reportMeeting({
        meetingId: st.meeting._id,
        subject: st.subject,
        studentsAttention: Math.round(st.studentsAttention / 10),
        studentsParticipation: Math.round(st.studentsParticipation / 10),
        studentsPerformance: Math.round(st.studentsPerformance / 10),
        comments: st.comments,
        attended: this.props.route.params.attended  //passed on from AttendanceReport
      });
      this.setState({isSubmitting: false});
      if (meetingReported) {
        history().push("/meetingList");
        return;
      }
    } catch (e) {
      Toast.show({
        type: 'error',
        text1: e.message,
        position: "bottom",
        bottomOffset: 100
      });
    }
    this.setState({isSubmitting: false});
  }
}

function CommentsInput (props) {
  return (
    <TextInput style={style.textInput}
               type="text"
               multiline
               autogrow="true"
               maxHeight={100}
               value={props.value}
               onChangeText={props.onChangeText}
               textAlignVertical="top"
               placeholder={props.placeholder}/>
  );
}