import React from 'react';
import {palette} from "../style/palette";
import {View, StyleSheet} from 'react-native';
import {Meeting} from './meeting';

const style = StyleSheet.create({
  meetingPanel: {
    flexGrow: 0,
    flexShrink: 0,
    paddingLeft: 50,
    paddingRight: 50,
    paddingTop: 20,
    borderBottomWidth: 1,
    borderBottomColor: palette.$accent1Tint1
  },
});

export function MeetingPanel(props) {
  return (
    <View style={style.meetingPanel}>
      <Meeting meeting={props.meeting}/>
    </View>
  );
}