import React from 'react';
import {TouchableOpacity, ScrollView, View, Text, StyleSheet, Image} from 'react-native';
import {Entypo} from "@expo/vector-icons";
import {classRequestApi} from "../api/classRequestApi";
import {pricingApi} from "../api/pricingApi";
import {teacherApi} from '../api/teacherApi';
import {Spinner} from "../controls/spinner";
import {palette} from "../style/palette";
import moment from "moment";
import history from '../history/history';
import {acceptanceStates} from "./courseProposals";
import {proposalFinalStates} from "./courseProposals";
import {xratesApi} from "../api/xratesApi";
import {log} from "../logger/logger";
import {ConfirmationDialog} from "../controls/confirmationDialog";
import {subjectHumanReadableNames} from "../courses/subjectNames";

let xrates = {};

const btnSize = 60;

const buttonStyle = {
  width: btnSize,
  height: btnSize,
  borderRadius: btnSize,
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "row",
};

const headerStyle = {
  backgroundColor: palette.$accent1Shade2,
  color: "white",
  fontSize: 18,
  width: "100%",
  padding: 10,
  fontWeight: "700",
  textAlign: "center",
};

const yesIcon = {
  size: btnSize * 0.8,
  color: "white"
};

const noIcon = {
  size: btnSize * 0.8,
  color: palette.$accent1Shade2
};

const noChosenButtonColor = "white";
const noNotChosenButtonColor = palette.$accent1Tint2;


const style = StyleSheet.create({
  courseProposalDetailsScreen: {
    backgroundColor: palette.$accent1Shade3,
    alignItems: "center",
    justifyContent: "flex-end",
    flexGrow: 1,
  },

  courseProposalDetails: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },

  dimBackground: {
    backgroundColor: 'rgba(0,0,0,0.5)'
  },

  header: {
    ...headerStyle,
  },
  headerRejected: {
    ...headerStyle,
    backgroundColor: "#f00"
  },
  headerAccepted: {
    ...headerStyle,
    backgroundColor: "green"
  },
  label: {
    color: palette.$accent1,
    fontSize: 16,
    marginTop: 20,
    marginBottom: 5,
    fontWeight: "700",
    textAlign: "center",
  },
  content: {
    color: "white",
    fontSize: 14,
    fontWeight: "700",
    textAlign: "left",
    paddingHorizontal: 30,
  },
  decisionPanel: {
    backgroundColor: palette.$accent1Tint1,
    width: "100%",
    marginTop: 20,
    paddingBottom: 30,
    alignItems: "center",
  },
  decisionQuestion: {
    color: palette.$accent1Shade2,
    fontSize: 18,
    padding: 20,
    textAlign: "center",
    lineHeight: 27,
  },
  yesNoButtonsPanel: {
    width: "80%",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  yesButton: {
    ...buttonStyle,
    backgroundColor: palette.$accent1Shade2
  },
  yesChosenButton: {
    ...buttonStyle,
    backgroundColor: "green"
  },
  yesNotChosenButton: {
    ...buttonStyle,
    backgroundColor: "#aaa"
  },
  noButton: {
    ...buttonStyle,
    backgroundColor: "white"
  },
  noChosenButton: {
    ...buttonStyle,
    backgroundColor: "red"
  },
  noNotChosenButton: {
    ...buttonStyle,
    backgroundColor: "#aaa"
  },
});

export class CourseProposalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.loadState = this.loadState.bind(this);
    this.acceptCourseProposal = this.acceptCourseProposal.bind(this);
    this.rejectCourseProposal = this.rejectCourseProposal.bind(this);
  }

  async loadState() {
    const courseProposal = await classRequestApi.getCourseProposal(this.props.route.params.courseProposal._id);
    let pricing = {
      teacherFees: {}
    };
    try {
      pricing = await pricingApi.getPricing(courseProposal.type, courseProposal.capacity, courseProposal.subject);
      if (courseProposal.type === 'onlineGroup') {
        delete pricing.teacherFees[1];
        delete pricing.jun24TeacherFees[1];
      }
    } catch (e) {
      log.error(e);
    }
    let preferredCurrency;
    try {
      const teacher = await teacherApi.getTeacher();
      preferredCurrency = teacher.preferredCurrency;
    } catch (e) {
      log.error(e);
    }
    if (preferredCurrency) {
      const {rates} = await xratesApi.getExchangeRates("IDR", [preferredCurrency]);
      xrates = rates;
    }
    this.setState({
      courseProposal,
      pricing,
      preferredCurrency,
      acceptingProposal: false
    });
  }

  componentDidMount() {
    this.removeFocusListener = this.props.navigation.addListener('focus', this.loadState);
  }

  componentWillUnmount() {
    this.removeFocusListener();
  }

  render() {
    const proposal = this.state.courseProposal;
    const pricing = this.state.pricing;
    const preferredCurrency = this.state.preferredCurrency;

    if (!proposal) return <Spinner/>;

    let header;
    if (proposal.finalState === proposalFinalStates.WITHDRAWN) {
      header = <Text style={style.headerRejected}>WITHDRAWN BY THE STUDENT</Text>
    } else if (proposal.acceptanceState === acceptanceStates.ACCEPTED) {
      return <AcceptedProposalDetails proposal={proposal}/>
    } else if (proposal.acceptanceState === acceptanceStates.REJECTED) {
      header = <Text style={style.headerRejected}>THE STUDENT CHOSE ANOTHER TEACHER</Text>
    } else if (proposal.acceptanceState === acceptanceStates.INTERESTED) {
      header = <Text style={style.headerAccepted}>PROPOSAL ACCEPTED BY YOU</Text>
    } else if (proposal.acceptanceState === acceptanceStates.NOT_INTERESTED) {
      header = <Text style={style.headerRejected}>PROPOSAL REJECTED BY YOU</Text>
    } else {
      header = <Text style={style.header}>COURSE PROPOSAL</Text>
    }
    return (
      <>
      {header}
      <ScrollView contentContainerStyle={[style.courseProposalDetailsScreen, this.state.acceptingProposal && style.dimBackground]}>
        <View style={style.courseProposalDetails}>
          <Label>NAME</Label>
          <Content>{proposal.name}</Content>
          <Label>SCHEDULE</Label>
          {proposal.schedule.map((sc) =>
            <Content key={JSON.stringify(sc)}>{formatSchedule(sc, proposal.timezone)}</Content>
          )}
          <Label>START DATE (tentative)</Label>
          <Content>{moment(proposal.startDate).format("DD-MMMM-YYYY")}</Content>
          <Label>GROUP SIZE</Label>
          <Content>{proposal.capacity <= 1 ? "Individual (1 student)" : `Group (${proposal.capacity} students)`}</Content>
          <Label>SUBJECT</Label>
          <Content>{subjectHumanReadableNames[proposal.subject] || proposal.subject}</Content>
          <Label>FEE (NEW CUSTOMERS)</Label>
          {Object.keys(pricing.jun24TeacherFees).length ? displayFee(pricing.jun24TeacherFees, preferredCurrency) : "Could not determine fee"}
          <Label>FEE (PRE-HIKE CUSTOMERS)</Label>
          {Object.keys(pricing.teacherFees).length ? displayFee(pricing.teacherFees, preferredCurrency) : "Could not determine fee"}
          <Label>FREQUENCY</Label>
          <Content>{proposal.schedule.length}x per week (first meeting is trial)</Content>
          {proposal.level && <>
            <Label>LEVEL</Label>
            <Content>{proposal.level}</Content>
          </>}
          <Label>STUDENT'S AGE</Label>
          <Content>{proposal.age[0] === 24 ? "adult" : `${proposal.age[0]}-${proposal.age[1]}`}</Content>
          {proposal.comments && proposal.comments.length > 0 && <>
            <Label>COMMENTS</Label>
            <Content>{proposal.comments}</Content>
          </>}
        </View>
        {!proposal.finalState && proposal.acceptanceState !== acceptanceStates.REJECTED &&
          <DecisionPanel acceptanceState={proposal.acceptanceState}
                         onInterested={this.confirmCourseProposal}
                         onNotInterested={this.rejectCourseProposal}/>
        }
      </ScrollView>
      {this.state.acceptingProposal &&
        <ConfirmationDialog
          message={`Accept course proposal ${proposal.name}?`}
          onConfirm={this.acceptCourseProposal}
          onCancel={this.cancelConfirmation}
        />
      }
      </>
    );
  }

  confirmCourseProposal = () => {
    this.setState({acceptingProposal: true});
  };

  cancelConfirmation = () => {
    this.setState({acceptingProposal: false});
  }

  async rejectCourseProposal() {
    await classRequestApi.rejectCourseProposal(this.state.courseProposal._id);
    //TODO: add spinner
    history().push('/courseProposalsList');
  }

  async acceptCourseProposal() {
    await classRequestApi.acceptCourseProposal(this.state.courseProposal._id);
    //TODO: add spinner
    history().push('/courseProposalsList');
  }
}

function Label(props) {
  return <Text style={style.label} {...props}>{props.children}</Text>
}

function Content(props) {
  return <Text style={style.content} {...props}>{props.children}</Text>
}

const acceptanceTitleStyle = {
  textTransform: "uppercase",
  color: palette.$accent1Tint1,
  paddingBottom: 10,
  letterSpacing: 0.8
};

const acceptanceBodyStyle = {
  color: "#fff",
  textAlign: "center",
  lineHeight: 20,
  letterSpacing: 0.8
};

const acceptedStyle = StyleSheet.create({
  img: {
    height: 360,
    flexShrink: 1,
    width: "100%",
    alignItems: "center",
    resizeMode: "cover",
  },
  lightPanel: {
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 0,
    padding: 20,
    backgroundColor: palette.$accent1Shade2,
  },
  darkPanel: {
    alignItems: "flex-end",
    flexGrow: 1,
    padding: 20,
    backgroundColor: palette.$accent1Shade3,
  },
  msgTitleLight: {
    ...acceptanceTitleStyle,
  },
  msgBodyLight: {
    ...acceptanceBodyStyle,
    textTransform: "uppercase",
  },
  msgTitleDark: {
    ...acceptanceTitleStyle,
    fontWeight: "700",
  },
  msgBodyDark: {
    ...acceptanceBodyStyle,
    fontWeight: "700",
    textAlign: "right"
  },
});

function AcceptedProposalDetails({proposal}) {
  return (
    <>
    <Image style={acceptedStyle.img} source={require("../../assets/acceptedProposal.jpg")}/>
    <View style={acceptedStyle.lightPanel}>
      <Text style={acceptedStyle.msgTitleLight}>Congratulations!</Text>
      <Text style={acceptedStyle.msgBodyLight}>- Course: {proposal.name} -</Text>
      <Text style={acceptedStyle.msgBodyLight}>- Subject: {subjectHumanReadableNames[proposal.subject]} -</Text>
      <Text style={acceptedStyle.msgBodyLight}>The student has agreed to start classes with you</Text>
    </View>
    <View style={acceptedStyle.darkPanel}>
      <Text style={acceptedStyle.msgTitleDark}>Reminder</Text>
      <Text style={acceptedStyle.msgBodyDark}>The tentative start date and schedule are</Text>
      <Text style={acceptedStyle.msgBodyDark} >{moment(proposal.startDate).format("DD-MMM-YYYY")}</Text>
        {proposal.schedule.map((sc) =>
          <Text style={acceptedStyle.msgBodyDark} key={JSON.stringify(sc)}>{formatSchedule(sc, proposal.timezone)}</Text>
        )}
      <Text style={acceptedStyle.msgBodyDark}>We will contact you to confirm</Text>
    </View>
    </>
  );
}

function DecisionPanel(props) {
  const accState = props.acceptanceState;
  let yesButtonStyle, noButtonStyle, yesIconColor, noIconColor, decisionQuestion, mindChangingNotice;
  switch (accState) {
    case acceptanceStates.NEW:
      decisionQuestion = "DO YOU WANT TO TEACH THIS COURSE?";
      yesButtonStyle = style.yesButton;
      yesIconColor = yesIcon.color;
      noButtonStyle = style.noButton;
      noIconColor = noIcon.color;
      break;
    case acceptanceStates.NOT_INTERESTED:
      decisionQuestion = "YOU HAVE REJECTED THIS PROPOSAL";
      mindChangingNotice = "IF YOU WANT TO CHANGE YOUR MIND, TAP THE TICK SIGN";
      yesButtonStyle = style.yesNotChosenButton;
      yesIconColor = yesIcon.color;
      noButtonStyle = style.noChosenButton;
      noIconColor = noChosenButtonColor;
      break;
    case acceptanceStates.INTERESTED:
      decisionQuestion = "YOU HAVE ALREADY ACCEPTED THIS PROPOSAL";
      mindChangingNotice = "IF YOU WANT TO CHANGE YOUR MIND, TAP THE CROSS SIGN";
      yesButtonStyle = style.yesChosenButton;
      yesIconColor = yesIcon.color;
      noButtonStyle = style.noNotChosenButton;
      noIconColor = noNotChosenButtonColor;
      break;
  }

  return (
    <View style={style.decisionPanel}>
      <Text style={style.decisionQuestion}>{decisionQuestion}</Text>
      <View style={style.yesNoButtonsPanel}>
        <TouchableOpacity style={noButtonStyle} onPress={props.onNotInterested}>
          <Entypo name="cross" size={noIcon.size} color={noIconColor}/>
        </TouchableOpacity>
        <TouchableOpacity style={yesButtonStyle} onPress={props.onInterested}>
          <Entypo name="check" size={yesIcon.size} color={yesIconColor}/>
        </TouchableOpacity>
      </View>
      {mindChangingNotice && <Text style={style.decisionQuestion}>{mindChangingNotice}</Text>}
    </View>
  );
}

function formatSchedule(sc, tz) {
  const {dayOfWeekStr, startStr, endStr} = getFormattedScheduleParts(sc, tz);
  return `${dayOfWeekStr} | ${startStr} - ${endStr}`;
}

export function getFormattedScheduleParts(sc, tz) {
  //FIXME: this WILL fail if daylight savings start between the schedule date this week and the actual start date of the course (say, today is Friday, the schedule is Tue and daylight savings start tomorrow)
  const schStartTimeThisWeek = moment(moment.tz(tz).startOf("day").isoWeekday(sc.dayOfWeek).hours(0).minutes(sc.startHours * 60).valueOf()); //The outer moment is to change it to the local timezone, which might affect the day of week as anything else
  const dayOfWeekStr = schStartTimeThisWeek.format('ddd');
  const startStr = schStartTimeThisWeek.format('HH:mm');
  const endStr = schStartTimeThisWeek.add(sc.durationMins, 'minutes').format('HH:mm');
  return {dayOfWeekStr, startStr, endStr};
}

function displayFee(teacherFees, preferredCurrency) {
  return (
    <View>
      {
        Object.keys(teacherFees).map((numEnrollees) => (
          <Content key={numEnrollees}>
            {numEnrollees} student(s): {calcPrice(teacherFees[numEnrollees], preferredCurrency)}
          </Content>
        ))
      }
    </View>
  );
}

function calcPrice(price, preferredCurrency){
  const priceIDR = price;
  let priceInPreferredCurrencyText = "";
  if (xrates && preferredCurrency && preferredCurrency !== "IDR" && xrates[preferredCurrency]) {
    const preferredCurrencyPrice = priceIDR * xrates[preferredCurrency];
    priceInPreferredCurrencyText = `(~${preferredCurrency} ${preferredCurrencyPrice.toLocaleString("en", {maximumFractionDigits: 2, minimumFractionDigits: 2})}) `;
  }
  return `IDR ${priceIDR} ${priceInPreferredCurrencyText}per hour`;
}