import React from 'react';
import {StyleSheet, TouchableOpacity, View, TextInput, FlatList, Text} from 'react-native';
import {vocabularyApi} from "../api/vocabularyApi";
import history from "../history/history";
import {courseApi} from "../api/courseApi";
import {palette} from "../style/palette";
import * as _ from "lodash";
import {ConfirmationDialog} from '../controls/confirmationDialog';
import {Button} from '../controls/button';
import {Entypo} from "@expo/vector-icons";
import Toast from 'react-native-toast-message';

export const style = StyleSheet.create({
  inputContainer: {
    alignSelf: "center",
  },
  pair: {
    flexDirection: "row",
    borderRadius: 5,
    backgroundColor: "white",
  },
  pairText: {
    flex: 1,
    color: "black",
    margin: 10
  },
  studentNamesForCardContainer: {
    marginLeft: 10
  },
  pairSelected: {
    backgroundColor: palette.$greyText
  },
  pairSelectedText: {
    color: "white",
  },
  input: {
    fontSize: 20,
    height: 40,
    backgroundColor: "white",
    borderRadius: 6,
    marginVertical: 5,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  cardsList: {
    backgroundColor: "white",
    alignSelf: "center",
    borderRadius: 10,
    marginVertical: 5,
    padding: 5,
    width: "100%"
  },
  card: {
    borderBottomWidth: 2,
    borderColor: palette.$grey
  },
  selectedCards: {
    padding: 10,
    borderRadius: 10,
    backgroundColor: palette.$grey
  }
});


export class VocabularyEditor extends React.Component {
  constructor(props) {
    super(props);
    if (props.route.params && props.route.params.meeting) {
      this.meeting = props.route.params.meeting
    } else if (props.meeting) {
      this.meeting = props.meeting
    } else {
      history().push("/meetingList");
    }
    this.state = {
      cards: [],
      selectedCards: new Set(),
      duplicateKey: null,
      successMessage: null
    };
    this.studentsById = {};
    (async () => {
      const students = await courseApi.getStudents(this.meeting.course._id);
      students.forEach(s => this.studentsById[s._id] = s);
    })();
    this.searchPairs = _.debounce(this.searchPairs.bind(this), 500);
    this.renderCard = this.renderCard.bind(this);
    this.submitCard = this.submitCard.bind(this);
    this.addPair = this.addPair.bind(this);
    this.duplicateConfirm = this.duplicateConfirm.bind(this);
    this.duplicateCancel = this.duplicateCancel.bind(this);
    this.checkDuplicates = this.checkDuplicates.bind(this);
  }

  render() {
    const cards = this.state.cards;

    return (
      <View style={{flex: 1}}>
        <View style={style.inputContainer}>
          <TextInput style={style.input}
                    onChangeText={(searchString) => {
                      this.setState({stringA: searchString})
                      this.searchPairs(searchString)
                    }}
                    autoCapitalize="none"
                    placeholder="Native word"
                    value={this.state.stringA}/>
          <TextInput style={style.input}
                    onChangeText={(searchString) => {
                      this.setState({stringB: searchString})
                      this.searchPairs(searchString)
                    }}
                    autoCapitalize="none"
                    placeholder="English word"
                    value={this.state.stringB}/>
          {/* Need the below view orelse button won't stretch upto the parent containers width in android, but does in web. */}
          <View>
            <Button
              onPress={this.submitCard}
              disabled={!(this.state.searched && (this.state.stringA && this.state.stringA.length > 2) && (this.state.stringB && this.state.stringB.length > 2))}
              title={this.state.selectedCards.size > 0 ? "Replace" : "Add"}
              style={{marginVertical: 5}}
            />
          </View>
          {this.state.selectedCards.size > 0 &&
            <View style={style.selectedCards}>
              <Text>Replace:</Text>
              {[...this.state.selectedCards].map((card, idx) => (
                <Text key={idx}>{card.a}, {card.b}</Text>
              ))}
              <TouchableOpacity
                style={{position: "absolute", right: 5, top: 5}}
                onPress={() => {
                  this.setState({selectedCards: new Set()});
                }}>
                <Entypo name="cross"/>
              </TouchableOpacity>
            </View>
          }

        </View>
        {cards.length > 0 &&
          <FlatList
            style={style.cardsList}
            data={cards}
            keyExtractor={card => card._id}
            renderItem={this.renderCard}
          />
        }
        {this.state.duplicateKey &&
          <ConfirmationDialog
            message={`There’s already a card that contains the word ${this.state.duplicateKey}, you’re adding a new card with the same word. Are you sure you want to do that?`}
            onConfirm={this.duplicateConfirm}
            onCancel={this.duplicateCancel}/>
        }
        <Toast/>
      </View>
    );
  }

  async searchPairs(searchStr) {
    if (searchStr.length > 2) {
      const pairs = await vocabularyApi.searchPairs(Object.keys(this.studentsById), searchStr);
      const cards = this.groupPairs(pairs);
      cards.sort((c1, c2) => {
        const sideToSort = card => card.a.includes(searchStr) ? "a" : "b";
        return c1[sideToSort(c1)] > c2[sideToSort(c2)] ? 1 : -1;
      });
      this.setState({
        cards,
        searched: true
      });
    } else if (!searchStr.length) {
      this.setState({
        cards: [],
        searched: false
      });
    }
  }

  groupPairs(pairs) {
    const cardsByContent = new Map();
    pairs.forEach(p => {
      let card = cardsByContent.get(`${p.a}¬${p.b}`);
      if (!card) {
        card = {a: p.a, b: p.b, students: [], pairs: [], _id: p._id};
        cardsByContent.set(`${p.a}¬${p.b}`, card);
      }
      card.pairs.push(p);
      card.students.push(this.studentsById[p.userId]);
    });
    return Array.from(cardsByContent.values());
  }

  renderCard({item: c}) {
    const checked = this.state.selectedCards.has(c);
    return (
      <View style={style.card}>
        <TouchableOpacity onPress={() => this.setState(currSt => {
                const checked = currSt.selectedCards.has(c);
                if (checked) {
                  currSt.selectedCards.delete(c);
                  currSt.stringA = "";
                  currSt.stringB = "";
                } else {
                  currSt.selectedCards = this.removeIncompatibleCards(currSt.selectedCards, c);
                  currSt.selectedCards.add(c);
                  currSt.stringA = c.a;
                  currSt.stringB = c.b;
                }
                return currSt;
              })}>
        <View style={[style.pair, checked && style.pairSelected]}>
          <Text style={[style.pairText, {borderRightWidth: 1, borderRightColor: palette.$accent1Shade2}, checked && style.pairSelectedText]}>{c.a}</Text>
          <Text style={[style.pairText, checked && style.pairSelectedText]}>{c.b}</Text>
        </View>
        </TouchableOpacity>
        {c.students.length === Object.keys(this.studentsById).length ?
          null :
          <View style={style.studentNamesForCardContainer}>
            <Text style={{color: palette.$greyText, fontSize: 12}}>{c.students.map(s => s.name).join(', ')}</Text>
          </View>
        }
      </View>
    );
  }

  removeIncompatibleCards(selectedCards, newCard) {
    return new Set(Array.from(selectedCards.values()).filter(c => !c.students.some(st => newCard.students.includes(st))));
  }

  async submitCard() {
    if (this.state.selectedCards.size === 0) {
      if (!(await this.checkDuplicates(this.state.stringA, this.state.stringB)))
        await this.addPair();
      else
        return;
    } else {
      for (let selectedCard of this.state.selectedCards) {
        const studentPairsInSelectedCard = selectedCard.pairs;
        for (let studentPair of studentPairsInSelectedCard) {
          studentPair.a = this.state.stringA;
          studentPair.b = this.state.stringB;
          await vocabularyApi.updatePair(studentPair);
        }
      }
      this.showSuccessMessage(`Pair (${stringA}, ${stringB}) updated successfully`);
    }
    this.resetToDefault();
  }

  resetToDefault() {
    this.setState({
      cards: [],
      selectedCards: new Set(),
      duplicateKey: null,
      stringA: "",
      stringB: ""
    });
  }

  async checkDuplicates(stringA, stringB) {
    const isADuplicate = (await vocabularyApi.searchPairs(Object.keys(this.studentsById), stringA)).filter(card => stringA === card["a"]);
    const isBDuplicate = (await vocabularyApi.searchPairs(Object.keys(this.studentsById), stringB)).filter(card => stringB === card["b"]);
    if (isADuplicate.length > 0) {
      this.setState({duplicateKey: stringA});
      return true;
    }
    if (isBDuplicate.length > 0) {
      this.setState({duplicateKey: stringB});
      return true;
    }
    return false;
  }

  async addPair() {
    const stringA = this.state.stringA;
    const stringB = this.state.stringB;
    try {
      await Promise.all(Object.keys(this.studentsById).map(userId => {
        vocabularyApi.addPair({userId, a: stringA, b: stringB});
      }));
      this.showSuccessMessage(`Pair (${stringA}, ${stringB}) added successfully`);
    } catch (e) {
      this.showErrorMessage(`Error adding pair (${stringA}, ${stringB}) to vocabulary. Error: ${e}`);
    }
  }

  showSuccessMessage(message) {
    Toast.show({
      type: 'success',
      text1: message,
      position: "top",
      topOffset: 160
    });
  }

  showErrorMessage(message) {
    Toast.show({
      type: 'error',
      text1: message,
      position: "top",
      topOffset: 160
    });
  }

  async duplicateConfirm() {
    this.setState({duplicateKey: null});
    await this.addPair();
    this.resetToDefault();
  }

  duplicateCancel() {
    this.setState({duplicateKey: null});
  }
}