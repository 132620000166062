import React, {useState} from 'react';
import history from "../history/history";
import {StyleSheet, View, ScrollView, Text} from 'react-native';
import {palette} from "../style/palette";
import {MeetingPanel} from "./meetingPanel";

const style = StyleSheet.create({
  classReport: {
    flexGrow: 1,
    flexShrink: 1,
    padding: 20,
  },
  label: {
    fontSize: 20,
    fontWeight: "700",
    marginTop: 5,
    color: palette.$accent1Shade1
  },
  text: {
    fontSize: 14,
    color: palette.$accent1Shade3,
  }
});

export function ReportViewer({route}) {
  const meeting = route.params.meeting;

  if (!meeting) history().goBack();

  const r = meeting.report;
  return (<>
    <MeetingPanel meeting={meeting}/>
    <ScrollView style={style.classReport}>
      <View>
        <Text style={style.label}>Subject</Text>
        <Text style={style.text}>{r.subject}</Text>

        <Text style={style.label}>Comments</Text>
        <Text style={style.text}>{r.comments}</Text>

        <Text style={style.label}>Attention</Text>
        <Bar val={r.studentsAttention}/>

        <Text style={style.label}>Performance</Text>
        <Bar val={r.studentsPerformance}/>

        <Text style={style.label}>Participation</Text>
        <Bar val={r.studentsParticipation}/>
      </View>
    </ScrollView>
  </>);
}

export function Bar({val}) {
  return <View style={{
    width: `${val * 10}%`,
    height: 2,
    marginVertical: 5,
    backgroundColor: palette.$accent1Shade2
  }}/>
}